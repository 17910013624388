<template>
  <v-container fluid class="mt-n12">
    <v-overlay :value="isLoading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="ma-0">
      <v-col cols="2" class="offset-2">
        <appPsychologistDetail></appPsychologistDetail>
        <appAdminNavigation></appAdminNavigation>
      </v-col>
      <v-col cols="6">
        <v-card width="100%" class="ma-1">
          <v-card-title>
            <div class="d-flex justify-center">
              Dashboard / Liste des rendez-vous à venir en consultation
              psychologique
            </div>
            <v-spacer></v-spacer>
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card width="100%" class="ma-1">
          <div v-if="nextAppointment !== null">
            <v-card-title> Votre prochain rendez-vous </v-card-title>
            <v-card-text v-if="appointments.length > 0">
              <div class="d-flex">
                {{ nextAppointment.client.fullName }} ({{
                  nextAppointment.client.email
                }})
                {{ nextAppointment.dateTimeStr }}
              </div>
            </v-card-text>
          </div>
          <v-card-title v-if="nextAppointment === null">
            Aucun rendez-vous à venir
          </v-card-title>
        </v-card>

        <v-alert
          type="success"
          dismissible
          class="ma-1"
          v-if="infoMessage !== null"
        >
          {{ infoMessage }}
        </v-alert>

        <v-card width="100%" class="ma-1">
          <v-tabs
            v-model="activeTabModel"
            background-color="white"
            color="primary accent-4"
            left
            @change="tabChanged"
          >
            <v-tab key="daily">Rendez-vous de la journée</v-tab>
            <v-tab key="weekly">Rendez-vous de la semaine</v-tab>

            <v-tab-item>
              <v-container fluid v-if="showTabDaily">
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="appointments"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :custom-sort="customSort"
                    class="elevation-1"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    @page-count="pageCount = $event"
                  >
                    <template v-slot:item.dateTime="{ item }">
                      {{ formatDateTime(item.dateTime) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom v-if="!item.doesReportExists">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            @click="createPvForItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-file-edit
                          </v-icon>
                        </template>
                        <span>Créer le PV</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.doesReportExists">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-2" v-bind="attrs" v-on="on">
                            mdi-blank
                          </v-icon>
                        </template>
                        <span>PV déjà créé</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.type === 'Ancien'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="appointmentListForItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-view-list
                          </v-icon>
                        </template>
                        <span>Liste des PVs</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.type !== 'Ancien'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"> mdi-blank </v-icon>
                        </template>
                        <span>Aucun PV</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-row>
                      <v-col>
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :total-visible="7"
                        ></v-pagination>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-select
                              v-model="itemsPerPage"
                              label="Nombre de rendez-vous par page"
                              :items="[1, 5, 10, 15]"
                              @input="itemsPerPage = parseInt($event, 10)"
                            ></v-select
                          ></v-col>
                          <v-col>
                            <v-text-field
                              :value="appointments.length"
                              label="Nombre total de rendez-vous"
                              disabled
                              class="mb-4 px-5"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-container>
            </v-tab-item>

            <v-tab-item>
              <v-container fluid v-if="showTabWeekley">
                <v-card-text>
                  <v-data-table
                    :headers="headers"
                    :items="appointments"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :custom-sort="customSort"
                    class="elevation-1"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    hide-default-footer
                    @page-count="pageCount = $event"
                  >
                    <template v-slot:item.dateTime="{ item }">
                      {{ formatDateTime(item.dateTime) }}
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip bottom v-if="!item.doesReportExists">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="mr-2"
                            @click="createPvForItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-file-edit
                          </v-icon>
                        </template>
                        <span>Créer le PV</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.doesReportExists">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon class="mr-2" v-bind="attrs" v-on="on">
                            mdi-blank
                          </v-icon>
                        </template>
                        <span>PV déjà créé</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.type === 'Ancien'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            @click="appointmentListForItem(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            mdi-view-list
                          </v-icon>
                        </template>
                        <span>Liste des PVs</span>
                      </v-tooltip>

                      <v-tooltip bottom v-if="item.type !== 'Ancien'">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon small v-bind="attrs" v-on="on">
                            mdi-blank
                          </v-icon>
                        </template>
                        <span>Aucun PV</span>
                      </v-tooltip>
                    </template>
                  </v-data-table>
                  <div class="text-center pt-2">
                    <v-row>
                      <v-col>
                        <v-pagination
                          v-model="page"
                          :length="pageCount"
                          :total-visible="7"
                        ></v-pagination>
                      </v-col>
                      <v-col>
                        <v-row>
                          <v-col
                            ><v-select
                              v-model="itemsPerPage"
                              label="Nombre de rendez-vous par page"
                              :items="[1, 5, 10, 15]"
                              @input="itemsPerPage = parseInt($event, 10)"
                            ></v-select
                          ></v-col>
                          <v-col>
                            <v-text-field
                              :value="appointments.length"
                              label="Nombre total de rendez-vous"
                              disabled
                              class="mb-4 px-5"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-container>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import moment from "moment";
import adminNavigation from "@/components/Admin/Navigation";
import psychologistDetail from "@/components/Admin/PsychologistDetail";
import {
  convertJsDateToDbDate,
  formatDateTime,
  formatTime,
} from "@/utils/date";

export default {
  data: () => ({
    headers: [
      {
        text: "Prénom & Nom du client",
        value: "client.fullName",
        sortable: false,
      },
      { text: "Date et heure du rendez-vous", value: "dateTime" },
      { text: "Type de client", value: "type" },
      { text: "Actions", value: "actions", sortable: false },
    ],

    activeTabModel: 0,

    showTabDaily: false,
    showTabWeekley: false,

    pageCount: 0,

    queryObj: {},

    infoMessage: null,
  }),

  computed: {
    ...mapGetters("layout", ["message"]),

    ...mapGetters("dataTable", {
      dataTableParams: "params",
      dataTableParamsForQueryString: "paramsForQueryString",
    }),

    ...mapGetters("adminDashboard", ["appointments", "isLoading"]),

    nextAppointment() {
      if (this.appointments.length === 0) {
        return null;
      }

      var apptList = [...this.appointments];
      apptList = apptList.sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));
      apptList = apptList.filter((element) => element.dateTime >= new Date());

      if (apptList.length === 0) {
        return null;
      }

      const nextAppointment = {
        client: {
          fullName: apptList[0].client.fullName,
          email: apptList[0].client.email,
        },
        dateTimeStr: null,
      };

      const nextApptMoment = moment(apptList[0].dateTime);
      if (nextApptMoment.format("YYYYMMDD") === moment().format("YYYYMMDD")) {
        nextAppointment.dateTimeStr = "à " + formatTime(apptList[0].dateTime);
      } else {
        nextAppointment.dateTimeStr = formatDateTime(apptList[0].dateTime);
      }
      return nextAppointment;
    },

    page: {
      get: function () {
        return this.dataTableParams.page;
      },
      set: function (newValue) {
        this.updateParams({ page: newValue });
      },
    },

    itemsPerPage: {
      get: function () {
        return this.dataTableParams.itemsPerPage;
      },
      set: function (newValue) {
        this.updateParams({ itemsPerPage: newValue });
      },
    },

    sortBy: {
      get: function () {
        return this.dataTableParams.sortBy;
      },
      set: function (newValue) {
        this.updateParams({ sortBy: newValue });
      },
    },

    sortDesc: {
      get: function () {
        return this.dataTableParams.sortDesc;
      },
      set: function (newValue) {
        this.updateParams({ sortDesc: newValue });
      },
    },
  },

  watch: {
    itemsPerPage() {
      this.updateParams({ itemsPerPage: this.itemsPerPage });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    page() {
      this.updateParams({ page: this.page });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    sortBy() {
      this.updateParams({ sortBy: this.sortBy });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },

    sortDesc() {
      this.updateParams({ sortDesc: this.sortDesc });
      this.queryObj = Object.assign(this.queryObj, this.dataTableParams);
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },
  },

  methods: {
    ...mapActions("layout", ["clearMessage"]),

    ...mapActions("dataTable", ["resetConfig", "updateParams"]),

    ...mapActions("adminDashboard", [
      "loadAppointmentsOfTheDay",
      "loadAppointmentsOfTheWeek",
      "setAppointments",
    ]),

    formatDateTime,
    formatTime,

    async appointmentsOfTheDay() {
      await this.loadAppointmentsOfTheDay();
    },

    async appointmentsOfTheWeek() {
      await this.loadAppointmentsOfTheWeek();
    },

    back() {
      this.$router.go(-1);
    },

    createPvForItem(item) {
      this.$router.push({
        name: "psychological-appointment-create-report",
        query: {
          datetime: convertJsDateToDbDate(item.dateTime),
          email: item.client.email,
          origin: "admin",
        },
      });
    },

    appointmentListForItem(item) {
      this.$router.push({
        name: "admin-report-list",
        params: { email: item.client.email },
      });
    },

    customSort: function (items, index, isDesc) {
      items.sort((a, b) => {
        if (index[0] == "dateTime") {
          if (!isDesc[0]) {
            return a[index] - b[index];
          } else {
            return b[index] - a[index];
          }
        }
      });
      return items;
    },

    async tabChanged() {
      if (this.activeTabModel === 0) {
        await this.appointmentsOfTheDay();
        this.showTabDaily = true;
        this.showTabWeekley = false;
      } else {
        await this.appointmentsOfTheWeek();
        this.showTabDaily = false;
        this.showTabWeekley = true;
      }

      this.resetConfig({
        page: 1,
        itemsPerPage: 10,
        sortBy: ["dateTime"],
        sortDesc: [false],
      });

      this.queryObj = {
        activeTab: this.activeTabModel,
      };
      this.$router.replace({ query: this.queryObj }).catch(() => {});
    },
  },

  async created() {
    if ("activeTab" in this.$route.query) {
      this.activeTabModel = parseInt(this.$route.query.activeTab);
    }

    if (this.activeTabModel === 0) {
      await this.appointmentsOfTheDay();
      this.showTabDaily = true;
    } else {
      await this.appointmentsOfTheWeek();
      this.showTabWeekley = true;
    }

    this.resetConfig({
      page: 1,
      itemsPerPage: 10,
      sortBy: ["dateTime"],
      sortDesc: [false],
    });

    this.updateParams(this.$route.query);

    this.queryObj = Object.assign(
      { activeTab: this.activeTabModel },
      this.dataTableParams
    );
    this.$router.replace({ query: this.queryObj }).catch(() => {});

    if (this.message !== null) {
      this.infoMessage = this.message;
      this.clearMessage();
    }
  },

  components: {
    appPsychologistDetail: psychologistDetail,
    appAdminNavigation: adminNavigation,
  },
};
</script>
