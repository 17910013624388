import axios from "axios";
import { v4 as uuidv4 } from 'uuid';

async function handleError(error) {
  const error_num = uuidv4();
  let error_log_failed = false;
  let log_message = '';

  if (error.isAxiosError) {
    // Handling axios errors
    const axiosConfig = error.config;

    // If the URL contains the word "logs," it means that the error cannot be logged through Kong. To avoid an endless loop, no attempt will be made to log the error.
    if (axiosConfig.url.includes('logs')) {
      error_log_failed = true;
    }

    // Request details
    const requestDetails = {
      baseURL: axiosConfig.baseURL,
      url: axiosConfig.url,
      method: axiosConfig.method,
      headers: axiosConfig.headers,
      data: axiosConfig.data,
    };

    // Response details if presents
    const responseDetails = error.response ? {
      status: error.response.status,
      statusText: error.response.statusText,
      headers: error.response.headers,
      data: error.response.data,
    } : null;

    log_message = JSON.stringify({"Axios error" : {"Request": requestDetails, "Response": responseDetails, "error_number": error_num}});
  } else {
    if (error.stack) {
      const fullStack = error.stack;
      
      // Simplify the backtrace by keeping only the first few lines.
      const simplifiedStack = fullStack
      .split('\n')
      .slice(0, 10) // Limit to 10 lines to simplify.
      .join('\n');


      const simplifiedErrorLog = {
        message: error.message,
        simplifiedStack: simplifiedStack,
      };
    
      log_message = JSON.stringify({"Error": simplifiedErrorLog, "error_number": error_num});
    } else {
      log_message = JSON.stringify({error, "error_number": error_num});
    }
    
    
  }

  if (! error_log_failed) {
    await axios.post("/logs", {level: "4", message: log_message});
  }

  const client_error_msg = `
  Une erreur est survenue. 
  Si le problème persiste, merci de contacter le support à l'adresse suivante: helpdesk@unil.ch, en indiquant le n° d'erreur suivant: ${error_num}
  `
  window.location.href = "/error/" + encodeURIComponent(client_error_msg);
}

export default handleError;