<template>
  <router-view />
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",

  computed: {
    ...mapGetters("user", ["isAuthenticated", "token"]),
  },

  methods: {
    ...mapActions("user", ["storeAuthenticationDataFromToken"]),
  },

  created() {
    if (Vue.$keycloak.token) {
      this.storeAuthenticationDataFromToken(Vue.$keycloak.tokenParsed);
    }
  },
};
</script>

<style src="./styles/app.scss" lang="scss" />
