<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Informations sur le rendez-vous</p>
          </v-card-title>

          <v-text-field
            v-model="appointmentDateTimeModel"
            label="Date et heure du rendez-vous *"
            disabled
            class="mb-4 px-5"
          ></v-text-field>

          <v-select
            v-model="motivationModel"
            :items="
              unilMember
                ? motivationsByAudience('permanency-unilMember')
                : motivationsByAudience('permanency-externalToUnil')
            "
            label="Motif de la consultation *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('motivationModel')"
            @change="$v.motivationModel.$touch()"
            @blur="$v.motivationModel.$touch()"
            class="mb-4 px-5"
          ></v-select>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { createDateTimeTz, formatDateTime } from "@/utils/date";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      appointmentDateTimeModel: null,
      motivationModel: null,
    };
  },

  computed: {
    ...mapGetters("permanencyReport", ["report"]),

    ...mapGetters("commonReportForm", [
      "isFormWritable",
      "motivationsByAudience",
      "motivationByValue",
    ]),

    rules() {
      let fieldRules = {
        motivationModel: { required },
      };

      return fieldRules;
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "motivationModel") {
          if (this.$v.motivationModel.$dirty) {
            !this.$v.motivationModel.required &&
              errors.push("Le motif de la consultation est requis !");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("permanencyReport", ["updateObjReport", "unilMember"]),

    ...mapActions("commonReportForm", [
      "incrementStep",
      "decrementStep",
      "resetStep",
    ]),

    formatDateTime,

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    prev() {
      this.decrementStep();
    },

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjReport({
        motivation: this.motivationModel,
      });

      this.incrementStep();
    },
  },

  created() {
    this.appointmentDateTimeModel = formatDateTime(
      createDateTimeTz(this.report.dateTime)
    );
    this.motivationModel = this.report.motivation;
  },
};
</script>
