<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Informations sur le rendez-vous</p>
          </v-card-title>

          <v-row>
            <v-menu
              v-model="menuDatePicker"
              :close-on-content-click="false"
              max-width="290"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="computedDateFormattedMomentjs"
                  :disabled="!isFormWritable"
                  clearable
                  label="Date du rendez-vous *"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  @click:clear="
                    appointmentDateModel = null;
                    appointmentTimeModel = null;
                  "
                  :error-messages="validationErrors('appointmentDateModel')"
                  @change="$v.appointmentDateModel.$touch()"
                  @blur="$v.appointmentDateModel.$touch()"
                  class="mb-4 px-8"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="appointmentDateModel"
                locale="fr"
                @change="menuDatePicker = false"
              ></v-date-picker>
            </v-menu>

            <v-menu
              ref="menu"
              v-model="menuTimePicker"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="appointmentTimeModel"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="appointmentTimeModel"
                  :disabled="!isFormWritable"
                  label="Heure du rendez-vous *"
                  prepend-icon="mdi-clock-time-four-outline"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="validationErrors('appointmentTimeModel')"
                  @change="$v.appointmentTimeModel.$touch()"
                  @blur="$v.appointmentTimeModel.$touch()"
                  class="mb-4 px-8"
                ></v-text-field>
              </template>
              <v-time-picker
                v-if="menuTimePicker"
                v-model="appointmentTimeModel"
                format="24hr"
                full-width
                @click:minute="$refs.menu.save(appointmentTimeModel)"
              ></v-time-picker>
            </v-menu>
          </v-row>

          <v-select
            v-model="typologyModel"
            :items="typologiesList"
            label="Typologie de la demande *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('typologyModel')"
            @change="$v.typologyModel.$touch()"
            @blur="$v.typologyModel.$touch()"
            class="mb-4 px-5"
            required
          ></v-select>
          <v-text-field
            v-model="typologyOtherModel"
            label="Autre typologie *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('typologyOtherModel')"
            @change="$v.typologyOtherModel.$touch()"
            @blur="$v.typologyOtherModel.$touch()"
            class="mb-4 px-5"
            v-if="typologyModel === 'OTHER'"
          ></v-text-field>

          <v-select
            v-model="motivationModel"
            :items="motivationsByAudience('psychological')"
            label="Motif de la consultation *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('motivationModel')"
            @change="$v.motivationModel.$touch()"
            @blur="$v.motivationModel.$touch()"
            class="mb-4 px-5"
          ></v-select>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { formatDate, formatDateTime } from "@/utils/date";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      menuDatePicker: false,
      menuTimePicker: false,
      appointmentDateModel: null,
      appointmentTimeModel: null,
      typologyModel: null,
      typologyOtherModel: null,
      motivationModel: null,
    };
  },

  computed: {
    ...mapGetters("psychologyReport", ["report"]),

    ...mapGetters("psychologyReportForm", ["typologiesList"]),

    ...mapGetters("commonReportForm", [
      "isFormWritable",
      "motivationsByAudience",
      "motivationByValue",
    ]),

    computedDateFormattedMomentjs() {
      if (!this.appointmentDateModel) {
        return "";
      }
      return formatDate(new Date(this.appointmentDateModel));
    },

    rules() {
      let fieldRules = {
        typologyModel: { required },
        motivationModel: { required },
        appointmentDateModel: { required },
        appointmentTimeModel: { required },
      };

      if (this.typologyModel === "OTHER") {
        fieldRules.typologyOtherModel = { required };
      }

      return fieldRules;
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "typologyModel") {
          if (this.$v.typologyModel.$dirty) {
            !this.$v.typologyModel.required &&
              errors.push("La typologie de la demande est requise !");
          }
          return errors;
        }

        if (field === "typologyOtherModel") {
          if (this.$v.typologyOtherModel.$dirty) {
            !this.$v.typologyOtherModel.required &&
              errors.push("Une autre typologie est requise !");
          }
          return errors;
        }

        if (field === "motivationModel") {
          if (this.$v.motivationModel.$dirty) {
            !this.$v.motivationModel.required &&
              errors.push("Le motif de la consultation est requis !");
          }
          return errors;
        }

        if (field === "appointmentDateModel") {
          if (this.$v.appointmentDateModel.$dirty) {
            !this.$v.appointmentDateModel.required &&
            errors.push("La date de la consultation est requise !");
          }
          return errors;
        }

        if (field === "appointmentTimeModel") {
          if (this.$v.appointmentTimeModel.$dirty) {
            !this.$v.appointmentTimeModel.required &&
            errors.push("L'heure de la consultation est requise !");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("psychologyReport", ["updateObjReport"]),

    ...mapActions("commonReportForm", [
      "incrementStep",
      "decrementStep",
      "resetStep",
    ]),

    formatDateTime,

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    prev() {
      this.decrementStep();
    },

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.updateObjReport({
        dateTime: this.appointmentDateModel === null ? null : `${this.appointmentDateModel}T${this.appointmentTimeModel}`,
        typology: this.typologyModel,
        typologyOther:
          this.typologyModel === "OTHER" ? this.typologyOtherModel : null,
        motivation: this.motivationModel,
      });

      this.incrementStep();
    },
  },

  created() {
    if (this.report.dateTime !== null) {
      this.appointmentDateModel = this.report.dateTime.split("T")[0];
      this.appointmentTimeModel = this.report.dateTime.split("T")[1];
    }
    this.typologyModel = this.report.typology;
    this.typologyOtherModel = this.report.typologyOther;
    this.motivationModel = this.report.motivation;
  },
};
</script>
