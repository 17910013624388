import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import jwtDecode from "jwt-decode";
import KeycloakPlugin from "@/plugins/keycloak";
import axios from "axios";
import VueI18n from "vue-i18n";
import VueLogger from "vuejs-logger";
import handleError from './error/errorHandler';

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

Vue.use(VueLogger, options);
Vue.use(KeycloakPlugin);
Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "fr",
  messages: { fr: require("@/locales/countries.json") },
});

// This function captures all unhandled JavaScript errors globally in the browser.
window.onerror = async function (message, source, lineno, colno, error) {
  await handleError({
    "Message": message,
    "Source": source,
    "Line": lineno,
    "Column": colno,
    "Error": error,
  });
};

// This event captures promises that are rejected without explicit error handling.
window.addEventListener("unhandledrejection", async function (event) {
  //event.promise contains the promise object
  //event.reason contains the reason for the rejection  
  await handleError(event.reason);
});

// This handler captures all errors specific to Vue.js, including errors in component lifecycles, events, etc.
Vue.config.errorHandler = async (err, vm, info) => {
  const fullStack = err.stack;

  // Simplify the backtrace by keeping only the first few lines.
  const simplifiedStack = fullStack
    .split('\n')
    .slice(0, 10) // Limit to 10 lines to simplify.
    .join('\n');

  // Create a simplified log
  const simplifiedErrorLog = {
    message: err.message,
    simplifiedStack: simplifiedStack,
    component: vm.$options.name || '(anonymous)',
    info: info,
    route: vm.$route ? vm.$route.fullPath : '(unknown)'
  };

  await handleError(simplifiedErrorLog);
};

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_BASE_URL;
axios.defaults.headers = { "api-key": process.env.VUE_APP_SOC_WS_API_KEY };

axios.interceptors.request.use((config) => {
  Vue.$log.info("Request interceptor", config);
  return config;
});

axios.interceptors.response.use((response) => {
  Vue.$log.info("Response interceptor", response);
  return response;
});

const signin = window.location.pathname === "/signin";
const error = window.location.pathname.includes("/error");

Vue.config.productionTip = false;

let refreshTokenExpired = true;
if (localStorage.getItem("vue-refresh-token")) {
  const expiratioDateTime = jwtDecode(
    localStorage.getItem("vue-refresh-token")
  ).exp;
  refreshTokenExpired = Date.now() >= expiratioDateTime * 1000;
}

if (!error && (signin || !refreshTokenExpired)) {
  Vue.$keycloak
    .init({ onLoad: "login-required", promiseType: "native" })
    .then((authenticated) => {
      if (!authenticated) {
        window.location.reload();
      } else {
        localStorage.setItem("vue-token", Vue.$keycloak.token);
        localStorage.setItem("vue-refresh-token", Vue.$keycloak.refreshToken);
      }

      new Vue({
        router,
        store,
        vuetify,
        i18n,
        render: (h) => h(App),
      }).$mount("#app");

      setInterval(() => {
        Vue.$keycloak
          .updateToken(70)
          .then((refreshed) => {
            if (refreshed) {
              Vue.$log.info("Token refreshed");
              Vue.$log.info("vue-token" + Vue.$keycloak.token);
              Vue.$log.info("vue-refresh-token" + Vue.$keycloak.refreshToken);
              localStorage.setItem("vue-token", Vue.$keycloak.token);
              localStorage.setItem("vue-refresh-token", Vue.$keycloak.refreshToken);
            } else {
              Vue.$log.info(
                "Token not refreshed, valid for " +
                  Math.round(
                    Vue.$keycloak.tokenParsed.exp +
                      Vue.$keycloak.timeSkew -
                      new Date().getTime() / 1000
                  ) +
                  " seconds"
              );
            }
          })
          .catch((error) => {
            Vue.$log.info("Failed to refresh token", error);
          });
      }, 60000);
    })
    .catch((error) => {
      Vue.$log.info("Authenticated Failed", error);
    });
} else {
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount("#app");
}
