<template>
  <v-app-bar class="main-header" color="primary" app>
    <v-row align="center" justify="center">
      <v-col cols="2">
        <v-img
          class="mx-2"
          src="@/assets/unil-logo-white.svg"
          max-height="60"
          max-width="172"
          min-width="80"
          contain
        ></v-img>
      </v-col>
      <v-col class="hidden-sm-and-down" style="text-align: center;">
        <span class="text-h4 white--text">Service Orientation & Carrières</span>
      </v-col>
      <v-col v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" style="text-align: center;">
        <span class="text-h4 white--text">SOC</span>
      </v-col>
      <v-col cols="2" class="d-flex justify-end"> </v-col>
    </v-row>
  </v-app-bar>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style src="./Header.scss" lang="scss"></style>
