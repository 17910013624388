<template>
  <v-container fluid class="booking mt-n12 mx-1">
    <v-overlay :value="isLoading || isSaving">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <p class="text-h4 text-center mb-10 hidden-sm-and-down">
      Demande de rendez-vous d'information
    </p>
    <p class="text-h7 text-center mb-10" v-show="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm">
      Demande de rendez-vous d'information
    </p>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="currentStep" class="transparent elevation-0">
          <v-card class="mb-5">
            <v-stepper-header>
              <v-stepper-step :complete="stepCompleted(1)" step="1"
                >Motif de la consultation</v-stepper-step
              >

              <v-divider></v-divider>
              <v-stepper-step :complete="stepCompleted(2)" step="2"
                >Choix de la date et de l'heure</v-stepper-step
              >
              <v-divider></v-divider>

              <v-stepper-step :complete="stepCompleted(3)" step="3"
                >Informations personnelles</v-stepper-step
              >
              <v-divider></v-divider>

              <v-stepper-step step="4">Confirmation</v-stepper-step>
            </v-stepper-header>
          </v-card>

          <v-card>
            <v-stepper-items>
              <v-stepper-content step="1">
                <app-motivation-selection />
              </v-stepper-content>

              <v-stepper-content step="2">
                <app-date-time-selection />
              </v-stepper-content>

              <v-stepper-content step="3">
                <app-personal-informations />
              </v-stepper-content>
              <v-stepper-content step="4">
                <app-confirmation />
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import MotivationSelection from "@/components/Appointment/Permanency/MotivationSelection";
import DateTimeSelection from "@/components/Appointment/Permanency/DateTimeSelection";
import PersonalInformations from "@/components/Appointment/Permanency/PersonalInformations";
import Confirmation from "@/components/Appointment/Permanency/Confirmation";

export default {
  computed: {
    ...mapGetters("appointment", ["isSaving"]),

    ...mapGetters("appointmentForm", {
      currentStep: "step",
    }),

    ...mapGetters("slot", ["isLoading"]),
  },

  methods: {
    stepCompleted(stepNumber) {
      return this.currentStep > stepNumber;
    },
  },

  components: {
    appMotivationSelection: MotivationSelection,
    appDateTimeSelection: DateTimeSelection,
    appPersonalInformations: PersonalInformations,
    appConfirmation: Confirmation,
  },
};
</script>

<style src="./Booking.scss" lang="scss"></style>
