<template>
  <v-app>
    <v-card>
      <Header />
      <Navigation :home="{to: {name: 'admin-dashboard'}, name: 'Dashboard'}" />
    </v-card>

    <v-main class="content mx-0 mx-md-2">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Header from "@/components/Header/Header";
import Navigation from "@/components/Navigation/Navigation";

export default {
  name: "AdminLayout",
  components: { Header, Navigation },
};
</script>

<style src="./Layout.scss" lang="scss" />
