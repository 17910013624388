<template>
  <div>
    <v-card width="100%" class="ma-1">
      <!-- <v-card-text>
        <div class="d-flex justify-center">
          <v-img
            :src="require(`@/assets/avatars/${client.avatar}`)"
            max-height="179"
            max-width="179"
            contain
          ></v-img>
        </div>
      </v-card-text> -->

      <v-card-title class="text-h6 justify-center"
        >{{ client.firstName }} {{ client.lastName }}
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <span class="font-weight-bold">E-mail:</span>
            <div>{{ client.email }}</div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col>
            <span class="font-weight-bold">Téléphone:</span>
            <div>
              {{ client.phone }}
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    client: { type: Object, required: true },
  },
};
</script>
