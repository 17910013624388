export default {
  namespaced: true,

  state: {
    currentStep: 1,

    isFormWritable: true,

    gendersList: [
      { value: "F", text: "Madame" },
      { value: "M", text: "Monsieur" },
      { value: "O", text: "Autre" },
    ],

    motivationsList: [
      {
        value: "SKILLS_ASSESSMENT",
        text: "Bilan de compétences",
        audiences: ["psychological"],
      },
      {
        value: "MASTER_SELECTION",
        text: "Choix du master",
        audiences: ["psychological"],
      },
      {
        value: "TRAINING_SELECTION",
        text: "Choix de formation (Doctorat, formation continue)",
        audiences: [
          "psychological",
          "permanency-unilMember",
          "permanency-externalToUnil",
        ],
      },
      {
        value: "REORIENTATION",
        text: "Réorientation",
        audiences: ["psychological", "permanency-unilMember"],
      },
      {
        value: "ADVICE_INTERNSHIP_JOBS",
        text: "Conseil stage / emploi",
        audiences: ["psychological", "permanency-unilMember"],
      },
      {
        value: "ADVICE_WORK_TECHNICS",
        text: "Conseil méthode de travail / adaptation",
        audiences: [
          "booking-unilMember",
          "psychological",
          "permanency-unilMember",
        ],
      },
      {
        value: "PERSONAL_ISSUES",
        text: "Difficultés personnelles / soutien général",
        audiences: [
          "booking-unilMember",
          "psychological",
          "permanency-unilMember",
        ],
      },
      {
        value: "UNIL_INFORMATION",
        text: "Informations sur Unil et formations",
        audiences: ["permanency-externalToUnil"],
      },
      {
        value: "OTHER",
        text: "Autre",
        audiences: [
          "psychological",
          "permanency-unilMember",
          "permanency-externalToUnil",
        ],
      },
    ],

    priorExperiencesList: [
      { value: "GYMNASIUM_MATURITY", text: "Maturité gymnasiale" },
      { value: "PRO_MATURITY", text: "Maturité professionnelle" },
      { value: "SPECIALIZED_MATURITY", text: "Maturité spécialisée" },
      { value: "GENERAL_CERTIFICATE", text: "Certificat de culture générale" },
      {
        value: "FEDERAL_CAPACITY_CERTIFICATE",
        text: "Certificat fédéral de capacité",
      },
      {
        value: "FEDERAL_PRO_CERTIFICATE",
        text: "Attestation fédérale de formation professionnelle",
      },
      { value: "UNIVERSITY_DEGREE", text: "Diplôme universitaire" },
      { value: "STRANGER_DEGREE", text: "Diplômes étrangers" },
      { value: "OTHER", text: "Autre" },
    ],

    admissionsList: [
      {
        value: "PRIOR_DEGREE",
        text: "Admission avec le diplôme préalable obtenu",
      },
      {
        value: "PRIOR_EXAM",
        text: "Admission par Examen préalable d’admission",
      },
      { value: "ON_FILE", text: "Admission par Admission sur dossier" },
    ],

    situationsList: [
      { value: "UNDER_STUDY", text: "En cours d'étude" },
      { value: "INTO_TRAINING", text: "En formation" },
      { value: "FAILURE", text: "En échec" },
      { value: "DEFINITIVE_FAILURE", text: "En échec définitif" },
      { value: "PRO", text: "En activité professionnelle" },
      { value: "UNEMPLOYED_WITH_SUBVENTION", text: "Au chômage" },
      { value: "UNEMPLOYED", text: "Sans emploi" },
      { value: "INTERNSHIP", text: "En stage" },
      { value: "OTHER", text: "Autre" },
    ],

    followUpDecisionsList: [
      {
        value: "PSYCHO_MEETING_REQUIRED",
        text: "Nécessite une consultation psychologique",
        audiences: ["permanency"],
      },
      {
        value: "PSYCHO_MEETING_NOT_REQUIRED",
        text: "Ne nécessite pas une consultation psychologique",
        audiences: ["permanency"],
      },
      {
        value: "FINISHED",
        text: "Consultation terminée",
        audiences: ["psychological"],
      },
      {
        value: "CANCELLED",
        text: "Consultation interrompue",
        audiences: ["psychological"],
      },
      {
        value: "ANOTHER_PSYCHO_MEETING_REQUIRED",
        text: "Nécessite une suite de consultation psychologique",
        audiences: ["psychological"],
      },
      {
        value: "MEDICAL_MEETING_REQUIRED",
        text: "Nécessite une consultation psychothérapeutique ou médicale",
        audiences: ["psychological"],
      },
    ],

    themesList: [
      {
        value: "UNIL_REORIENTATION",
        text: "Unil - Réorientation",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_REORIENTATION_DUE_FAILURE",
        text: "Unil - Réorientation suite à un échec",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_MASTER_CHOICE",
        text: "Unil - Choix d'un master",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_WORK_METHOD",
        text: "Unil - Méthodes de travail (stress, adaptation, examens)",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_PROFESSIONAL_PERSPECTIVES",
        text: "Unil- Perspectives professionnelles",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_CV_PROOFREADING_UNI_INTERNSHIP",
        text: "Unil-Relecture CV / code Unistages",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_JOB_INTERVIEW_PREPARATION",
        text: "Unil- Préparation entretien embauche",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_CONTINUING_EDUCATION",
        text: "Unil - Formation complémentaire ou continue",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_PHD_PROBLEM",
        text: "Unil - Problématique doctorant.es (charge travail, etc.)",
        audiences: ["permanency"],
      },
      {
        value: "UNIL_PERSONAL_ISSUES",
        text: "Unil- Difficultés personnelles",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_ADMISSION_QUESTIONS",
        text: "Hors-Unil - Questions sur immatriculation ou admission",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_ADMISSION_WITHOUT_MATURITY_INFORMATION",
        text: "Hors-Unil - Renseignements sur admission sans maturité",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_BACHELOR_OR_MASTER_INFORMATION",
        text: "Hors-Unil- Renseignements sur Bachelor ou Master",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_CHOICE_CONTINUING_EDUCATION",
        text: "Hors-Unil- Choix d'une formation complémentaire ou continue",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_STUDY_ORGANIZATION",
        text: "Hors-Unil- Organisation des études",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_PROFESSIONAL_PERSPECTIVES_QUESTIONS",
        text: "Hors-Unil - Questions sur les perspectives professionnelles",
        audiences: ["permanency"],
      },
      {
        value: "EXTERNAL_OTHER",
        text: "Autres Thématiques",
        audiences: ["permanency"],
      },
      {
        value: "DECISION_MAKING_STRATEGIES",
        text: "Stratégies de prise de décision",
        audiences: ["psychological"],
      },
      {
        value: "COMPETENCY_ANALYSIS",
        text: "Analyse des compétences",
        audiences: ["psychological"],
      },
      {
        value: "ORGANIZATIONAL_DIFFICULTIES",
        text: "Difficultés organisationnelles (méthode de travail, méthode d'apprentissage)",
        audiences: ["psychological"],
      },
      {
        value: "PERSONAL_ISSUES",
        text: "Difficultés personnelles (bien-être, santé, handicap)",
        audiences: ["psychological"],
      },
      {
        value: "SOCIAL_DIFFICULTIES",
        text: "Difficultés sociales (relationnel, insertion, culture)",
        audiences: ["psychological"],
      },
      {
        value: "LOGISTICAL_ISSUES",
        text: "Difficultés logistiques (finances, matériel, logement)",
        audiences: ["psychological"],
      },
      {
        value: "WORK_AND_LEARN_METHODS",
        text: "Informations sur les filières de formation",
        audiences: ["psychological"],
      },
      {
        value: "WORK_AND_LEARN_METHODS",
        text: "Méthodes de travail et d’apprentissage",
        audiences: ["psychological"],
      },
      {
        value: "STRESS",
        text: "Gestion du stress",
        audiences: ["psychological"],
      },
      {
        value: "APPLICATION_FILE",
        text: "Dossier de candidature",
        audiences: ["psychological"],
      },
      {
        value: "INTERVIEW_TIPS",
        text: "Techniques d’entretien d’embauche",
        audiences: ["psychological"],
      },
      {
        value: "PROFESSIONAL_OPPORTUNITIES_INFORMATION",
        text: "Informations sur les débouchés professionnels, marché du travail",
        audiences: ["psychological"],
      },
    ],
  },

  mutations: {
    incrementStep(state) {
      state.currentStep++;
      if (state.currentStep > 4) {
        state.currentStep = 4;
      }
    },

    decrementStep(state) {
      state.currentStep--;
      if (state.currentStep < 1) {
        state.currentStep = 1;
      }
    },

    lastStep(state) {
      state.currentStep = 4;
    },

    resetStep(state) {
      state.currentStep = 1;
    },

    readOnly(state) {
      state.isFormWritable = false;
    },

    writable(state) {
      state.isFormWritable = true;
    },
  },

  actions: {
    incrementStep({ commit }) {
      commit("incrementStep");
    },

    decrementStep({ commit }) {
      commit("decrementStep");
    },

    lastStep({ commit }) {
      commit("lastStep");
    },

    resetStep({ commit }) {
      commit("resetStep");
    },

    readOnly({ commit }) {
      commit("readOnly");
    },

    writable({ commit }) {
      commit("writable");
    },
  },

  getters: {
    step(state) {
      return state.currentStep;
    },

    isFormWritable(state) {
      return state.isFormWritable;
    },

    gendersList(state) {
      return state.gendersList;
    },

    motivationsList(state) {
      return state.motivationsList;
    },

    motivationsByAudience: (state) => (audience) => {
      const result = state.motivationsList.filter((element) => {
        return element.audiences.includes(audience);
      });

      return result;
    },

    motivationByValue: (state) => (value) => {
      const result = state.motivationsList.find(
        (element) => element.value === value
      );

      if (result === undefined) {
        return null;
      } else {
        return result.text;
      }
    },

    priorExperiencesList(state) {
      return state.priorExperiencesList;
    },

    admissionsList(state) {
      return state.admissionsList;
    },

    situationsList(state) {
      return state.situationsList;
    },

    followUpDecisionsList(state) {
      return state.followUpDecisionsList;
    },

    followUpDecisionsByAudience: (state) => (audience) => {
      const result = state.followUpDecisionsList.filter((element) => {
        return element.audiences.includes(audience);
      });

      return result;
    },

    themesList(state) {
      return state.themesList;
    },

    themesByAudience: (state) => (audience) => {
      const result = state.themesList.filter((element) => {
        return element.audiences.includes(audience);
      });

      return result;
    },
  },
};
