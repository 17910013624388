<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn
              class="button-shadow ml-3"
              color="primary"
              :disabled="!isFormWritable"
              @click="save"
            >
              Sauvegarder
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Consultation psychologique</p>
          </v-card-title>

          <v-select
            v-model="themesModel"
            :items="themesByAudience('psychological')"
            item-text="text"
            item-value="value"
            label="Thématiques abordées"
            :disabled="!isFormWritable"
            multiple
            chips
            class="mb-4 px-5"
          ></v-select>

          <v-select
            v-model="deliveredServicesModel"
            :items="deliveredServicesList"
            item-text="text"
            item-value="value"
            label="Prestations délivrées"
            :disabled="!isFormWritable"
            multiple
            chips
            class="mb-4 px-5"
          ></v-select>

          <v-select
            v-model="toolsModel"
            :items="toolsList"
            item-text="text"
            item-value="value"
            label="Outils utilisés"
            :disabled="!isFormWritable"
            multiple
            chips
            class="mb-4 px-5"
          ></v-select>
          <v-text-field
            v-model="toolOtherModel"
            label="Autre outil"
            :disabled="!isFormWritable"
            class="mb-4 px-5"
            v-if="this.toolsModel.includes('OTHER')"
          ></v-text-field>

          <v-textarea
            v-model="commentModel"
            class="pt-1 mb-4 px-5"
            label="Commentaires *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('commentModel')"
            @change="$v.commentModel.$touch()"
            @blur="$v.commentModel.$touch()"
            auto-grow
            :solo="validationErrors('commentModel').length === 0"
          ></v-textarea>

          <v-select
            v-model="followUpDecisionModel"
            :items="followUpDecisionsByAudience('psychological')"
            item-text="text"
            item-value="value"
            label="Décision sur la suite de la consultation *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('followUpDecisionModel')"
            @change="$v.followUpDecisionModel.$touch()"
            @blur="$v.followUpDecisionModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-text-field
            v-model="durationModel"
            label="Durée de la consultation (en minutes) *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('durationModel')"
            @change="$v.durationModel.$touch()"
            @blur="$v.durationModel.$touch()"
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="socCollaboratorLastNameModel"
            label="Nom de famille du collaborateur SOC"
            disabled
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="socCollaboratorFirstNameModel"
            label="Prénom du collaborateur SOC"
            disabled
            class="mb-4 px-5"
          ></v-text-field>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn outlined color="primary" @click="back">
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn
              class="button-shadow ml-3"
              color="primary"
              :disabled="!isFormWritable"
              @click="save"
            >
              Sauvegarder
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, numeric } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      themesModel: [],
      deliveredServicesModel: [],
      toolsModel: [],
      toolOtherModel: null,
      commentModel: null,
      followUpDecisionModel: null,
      durationModel: null,
      socCollaboratorFirstNameModel: null,
      socCollaboratorLastNameModel: null,
    };
  },
  computed: {
    ...mapGetters("psychologyReport", ["report"]),

    ...mapGetters("psychologyReportForm", [
      "toolsList",
      "deliveredServicesList",
    ]),

    ...mapGetters("commonReportForm", [
      "isFormWritable",
      "themesByAudience",
      "followUpDecisionsByAudience",
    ]),

    rules() {
      return {
        commentModel: { required },
        followUpDecisionModel: { required },
        durationModel: { required, numeric },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "commentModel") {
          if (this.$v.commentModel.$dirty) {
            !this.$v.commentModel.required &&
              errors.push("Le commentaire est requise !");
          }
          return errors;
        }

        if (field === "followUpDecisionModel") {
          if (this.$v.followUpDecisionModel.$dirty) {
            !this.$v.followUpDecisionModel.required &&
              errors.push(
                "La décision sur la suite de la consultation est requise !"
              );
          }
          return errors;
        }

        if (field === "durationModel") {
          if (this.$v.durationModel.$dirty) {
            !this.$v.durationModel.numeric &&
              errors.push(
                "La durée de la consultation doit être indiquée en minutes !"
              );
            !this.$v.durationModel.required &&
              errors.push("La durée de la consultation est requise !");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("layout", ["setMessage"]),

    ...mapActions("psychologyReport", {
      updateObjReport: "updateObjReport",
      saveReport: "save",
    }),

    ...mapActions("commonReportForm", ["decrementStep", "resetStep"]),

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    prev() {
      this.decrementStep();
    },

    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjReport({
        themes: this.themesModel,
        deliveredServices: this.deliveredServicesModel,
        tools: this.toolsModel,
        toolOther: this.toolsModel.includes("OTHER")
          ? this.toolOtherModel
          : null,
        socCollaboratorComments: this.commentModel,
        socCollaboratorDecision: this.followUpDecisionModel,
        duration: this.durationModel,
      });

      this.saveReport();
      this.resetStep();
      this.setMessage("Le rapport a bien été enregistré");
      this.$router.go(-1);
    },
  },

  created() {
    this.themesModel = this.report.themes;
    this.deliveredServicesModel = this.report.deliveredServices;
    this.toolsModel = this.report.tools;
    this.toolOtherModel = this.report.toolOther;
    this.commentModel = this.report.socCollaboratorComments;
    this.followUpDecisionModel = this.report.socCollaboratorDecision;
    this.durationModel = this.report.duration;
    this.socCollaboratorFirstNameModel = this.report.socCollaboratorFirstName;
    this.socCollaboratorLastNameModel = this.report.socCollaboratorLastName;
  },
};
</script>
