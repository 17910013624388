import axios from "@/axios-auth";
import moment from "moment";
import { convertDbDateToJsDate } from "@/utils/date";

export default {
  namespaced: true,

  state: {
    appointments: [],

    nextAppointment: {
      fullName: null,
      email: null,
      dateTime: null,
    },

    isLoading: false,
  },

  mutations: {
    setAppointments(state, appointments) {
      state.appointments = appointments;
    },

    setNextAppointment(state, nextAppointment) {
      state.nextAppointment = nextAppointment;
    },

    loading(state) {
      state.isLoading = true;
    },

    loaded(state) {
      state.isLoading = false;
    },
  },

  actions: {
    async loadAppointmentsOfTheDay({ dispatch }) {
      const dateRange = {
        start: moment().format("YYYY-MM-DD"),
        end: moment().add(1, "days").format("YYYY-MM-DD"),
      };

      await dispatch("upcomingAppointments", dateRange);
    },

    async loadAppointmentsOfTheWeek({ dispatch }) {
      const dateRange = {
        start: moment().startOf("isoWeek").format("YYYY-MM-DD"),
        end: moment().endOf("isoWeek").add(1, "days").format("YYYY-MM-DD"),
      };

      await dispatch("upcomingAppointments", dateRange);
    },

    async loadReports(context, dateRange) {
      let response = await axios.get("/appointments/report/search", {
        params: { start: dateRange.start, end: dateRange.end },
      });

      return response.data;
    },

    async loadLastReportsByEmails(context, emails) {
      const uniqueEmails = emails.filter(
        (value, index, array) => array.indexOf(value) === index
      );

      let response = await axios.get("/appointments/reports/light/lasts", {
        params: { emails: uniqueEmails.join(",") },
      });

      return response.data;
    },

    async loadAppointments(context, data) {
      const queryParams = { ...data };
      Object.assign(queryParams, { email: this.getters["user/user"].email });
      let response = await axios.get("/appointments", {
        params: queryParams,
      });

      // One removes the appointments with the category "Permanency"
      const outlookAppointments = response.data.filter(
        (element) => !element.categories.includes("Permanency")
      );

      return outlookAppointments;
    },

    async upcomingAppointments({ dispatch, commit }, dateRange) {
      commit("loading");
      commit("setAppointments", []);

      let upcomingAppointments = [];
      const clientEmails = [];
      const data = { ...dateRange, psy: true };
      const outlookAppointments = await dispatch("loadAppointments", data);

      if (outlookAppointments.length === 0) {
        commit("loaded");
        return;
      }

      const existingReports = await dispatch("loadReports", dateRange);

      const that = this;
      outlookAppointments.forEach((item) => {
        const clientArry = item.attendees.filter(
          (element) => element.email !== that.getters["user/user"].email
        );
        if (clientArry.length !== 1) {
          throw new Error(
            "Only one attendee in addition to the psychologist is allowed !"
          );
        }

        upcomingAppointments.push({
          client: {
            fullName: clientArry[0].fullName,
            email: clientArry[0].email,
          },
          dateTime: convertDbDateToJsDate(item.slot.start),
          type: "Nouveau",
          doesReportExists: false,
        });

        clientEmails.push(clientArry[0].email);
      });

      const lastReports = await dispatch(
        "loadLastReportsByEmails",
        clientEmails
      );
      if (lastReports.length === 0) {
        commit("setAppointments", upcomingAppointments);
        commit("loaded");
        return;
      }

      existingReports.forEach((item) => {
        upcomingAppointments = upcomingAppointments.map((element) => {
          if (
            element.dateTime.getTime() ==
            convertDbDateToJsDate(item.dateTime).getTime()
          ) {
            element.doesReportExists = true;
          }

          return element;
        });
      });

      lastReports.forEach((item) => {
        upcomingAppointments = upcomingAppointments.map((element) => {
          if (element.client.email === item.email) {
            element.type = "Ancien";
          }
          return element;
        });
      });

      commit("setAppointments", upcomingAppointments);
      commit("loaded");
    },

    setAppointments({ commit }, appointments) {
      commit("setAppointments", appointments);
    },
  },

  getters: {
    appointments(state) {
      return state.appointments;
    },

    myNextAppointment(state) {
      return state.nextAppointment;
    },

    isLoading(state) {
      return state.isLoading;
    },
  },
};
