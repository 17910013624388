const isViewable = (report, socCollaborator) => {
  return (
    socCollaborator.roles.includes("soc-admin") ||
    report.type === "PERMANENCY" ||
    socCollaborator.pernum == report.socCollaboratorId
  );
};

const isEditable = (report, socCollaborator) => {
  return (
    socCollaborator.roles.includes("soc-admin") ||
    (report.type === "PSYCHOLOGY" &&
      socCollaborator.pernum == report.socCollaboratorId)
  );
};

export { isViewable, isEditable };
