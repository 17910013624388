<template>
  <v-container fluid class="mt-n12">
    <v-overlay :value="isLoading">
      <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </v-overlay>

    <v-row class="ma-0">
      <v-col cols="2" class="offset-2">
        <appPsychologistDetail></appPsychologistDetail>
        <appAdminNavigation></appAdminNavigation>
      </v-col>
      <v-col cols="6">
        <v-alert
            type="success"
            dismissible
            class="ma-1"
            v-if="infoMessage !== null"
        >
          {{ infoMessage }}
        </v-alert>

        <v-card width="100%" class="ma-1">
          <v-card-title> Créer un PV de consultation sans rendez-vous </v-card-title>
          <v-card-text>
            <div class="d-flex mt-1">
              <v-text-field
                  v-model="emailModel"
                  class="mb-4 px-5"
                  label="E-mail *"
                  required
                  :error-messages="validationErrors('emailModel')"
                  @change="$v.emailModel.$touch()"
                  @blur="$v.emailModel.$touch()"
              >
              </v-text-field>

              <v-btn class="button-shadow" color="primary" @click="createPv">
                Créer PV
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import adminNavigation from "@/components/Admin/Navigation";
import psychologistDetail from "@/components/Admin/PsychologistDetail";
import { convertJsDateToDbDate } from "@/utils/date";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      emailModel: '',
      infoMessage: null,
    };
  },

  computed: {
    ...mapGetters("layout", ["message"]),

    ...mapGetters("adminDashboard", ["appointments", "isLoading"]),

    rules() {
      return {
        emailModel: { required, email },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "emailModel") {
          if (this.$v.emailModel.$dirty) {
            !this.$v.emailModel.email &&
            errors.push("L'adresse e-mail est invalide");
            !this.$v.emailModel.required &&
            errors.push("L'adresse e-mail est requise");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("layout", ["clearMessage"]),

    createPv() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$router.push({
        name: "psychological-appointment-create-report",
         query: {
           datetime: convertJsDateToDbDate(new Date()),
           email: this.emailModel,
           origin: "admin",
         },
      });
    },
  },

  created() {
    if (this.message !== null) {
      this.infoMessage = this.message;
      this.clearMessage();
    }
  },

  components: {
    appPsychologistDetail: psychologistDetail,
    appAdminNavigation: adminNavigation,
  },
};
</script>
