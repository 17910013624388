import axios from "@/axios-auth";
// import axios from "axios";
// import { convertDbDateToJsDate } from "@/utils/date";

export default {
  namespaced: true,

  state: {
    clients: [],

    isLoading: false,
  },

  mutations: {
    setClients(state, clients) {
      state.clients = clients;
    },

    loading(state) {
      state.isLoading = true;
    },

    loaded(state) {
      state.isLoading = false;
    },
  },

  actions: {
    async loadClients({ commit }, payload) {
      commit("loading");

      const params = Object.assign(payload);

      let response = await axios.get("/psychology/reports/light/grouped", {
        params,
      });

      if (response.data.length === 0) {
        commit("setClients", []);
        commit("loaded");
        return;
      }

      const clientList = [];
      response.data.forEach((element) => {
        if (element.type !== "PSYCHOLOGY") {
          return;
        }

        clientList.push({
          clientFullName:
            element.lastName.toUpperCase() + " " + element.firstName,
          email: element.email,
          phone: element.phone,
          dateTime: element.dateTime,
          collaboratorFullName:
            element.socCollaboratorLastName.toUpperCase() +
            " " +
            element.socCollaboratorFirstName,
        });
      });

      commit("setClients", clientList);
      commit("loaded");
    },
  },

  getters: {
    clients(state) {
      return state.clients;
    },

    totalClients(state) {
      return state.clients.length;
    },

    isLoading(state) {
      return state.isLoading;
    },
  },
};
