<template>
  <div>
    <v-card width="100%" class="ma-1">
      <!-- <v-card-text>
        <div class="d-flex justify-center">
          <v-img
            src="@/assets/photo.png"
            max-height="179"
            max-width="179"
            contain
          ></v-img>
        </div>
      </v-card-text> -->

      <v-card-title class="text-h6 justify-center"
        >{{ collaboratorFirstName }} {{ collaboratorLastName }}</v-card-title
      >
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("user", {
      connectedUser: "user",
    }),

    collaboratorFirstName() {
      return this.connectedUser.firstName;
    },
    collaboratorLastName() {
      return this.connectedUser.lastName;
    },
  },
};
</script>
