import moment from "moment-timezone";
import config from "@/config";

const convertJsDateToDbDate = (date) => {
  const momentDate = moment.tz(date, config.timeZone);
  return momentDate.format("YYYY-MM-DDTHH:mm:ss");
};

const convertDbDateToJsDate = (date) => {
  const momentDate = moment.tz(date, config.timeZone);
  return momentDate.toDate();
};

const createDateTimeTz = (date, timeZone) => {
  let dateTimeZone = config.timeZone;
  if (typeof timeZone !== "undefined") {
    dateTimeZone = timeZone;
  }
  return moment.tz(date, dateTimeZone);
};

const formatDateTime = (date, formatOptions) => {
  let dateTimeFormatOptions = {};
  if (typeof formatOptions === "undefined") {
    dateTimeFormatOptions = config.dateTimeFormatOptions;
  } else {
    dateTimeFormatOptions = formatOptions;
  }
  return new Intl.DateTimeFormat(config.locale, dateTimeFormatOptions).format(
    date
  );
};

const formatDate = (date, formatOptions) => {
  let dateFormatOptions = {};
  if (typeof formatOptions === "undefined") {
    dateFormatOptions = config.dateFormatOptions;
  } else {
    dateFormatOptions = formatOptions;
  }
  return new Intl.DateTimeFormat(config.locale, dateFormatOptions).format(date);
};

const formatTime = (date, formatOptions) => {
  let timeFormatOptions = {};
  if (typeof formatOptions === "undefined") {
    timeFormatOptions = config.timeFormatOptions;
  } else {
    timeFormatOptions = formatOptions;
  }
  return new Intl.DateTimeFormat(config.locale, timeFormatOptions).format(date);
};

export {
  convertJsDateToDbDate,
  convertDbDateToJsDate,
  createDateTimeTz,
  formatDateTime,
  formatDate,
  formatTime,
};
