export default {
  light: {
    primary: "#018ccd", // "#536dfe",
    primaryConst: "#018ccd", //"#536dfe",
    secondary: "#00264d", // "#FF5C93",
    secondaryConst: "#00264d", // "#FF5C93",
    error: "#f2556e", // "#FF4081",
    warning: "#ffc566", // "#FFC260",
    success: "#93d783", // "#3CD4A0",
    successConst: "#93d783", // "#3CD4A0",
    info: "#9013FE",
    greyBold: "#707070", // "#4a4a4a",
    greyBoldConst: "#707070", // "#4a4a4a",
    greyMedium: "#bfbfbf", // "#6e6e6e",
    greyTint: "#d9d9d9", // "#b9b9b9",
    default: "#f0f0f0", // "#e0e0e0",
    iconColor: "#FFFFFF59",
  },
  dark: {
    primary: "#536dfe",
    primaryConst: "#536dfe",
    secondary: "#FF5C93",
    secondaryConst: "#FF5C93",
    success: "#3CD4A0",
    successConst: "#3CD4A0",
    error: "#FF4081",
    greyMedium: "#bfbfbf",
    greyBold: "#d6d6d6",
    greyBoldConst: "#4a4a4a",
    greyTint: "#9b9b9b",
    default: "#e0e0e0",
  },
  dateTimeFormatOptions: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/Zurich",
  },
  dateFormatOptions: {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    timeZone: "Europe/Zurich",
  },
  timeFormatOptions: {
    hour: "numeric",
    minute: "numeric",
    timeZone: "Europe/Zurich",
  },
  locale: "fr-CH",
  timeZone: "Europe/Zurich",
};
