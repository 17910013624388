<template>
  <v-card class="mx-auto my-10" max-width="500">
    <v-card-text>
      <p class="text-h5 text--primary text-center">
        Votre rendez-vous a bien été enregistré !
      </p>
      <p class="text-subtitle-2 text-center">
        Un e-mail de confirmation vous sera envoyé à l'adresse
        <span v-once>{{ email }}</span> dans les prochaines minutes.
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      email: null,
    };
  },
  computed: {
    ...mapGetters("appointment", ["appointment"]),
  },

  methods: {
    ...mapActions("appointment", ["resetAppointmentObj"]),
  },

  created() {
    this.email = this.appointment.email;
    this.resetAppointmentObj();
  },
};
</script>
