export default {
  namespaced: true,

  state: {
    message: null,
  },

  mutations: {
    setMessage(state, message) {
      state.message = message;
    },
  },

  actions: {
    setMessage({ commit }, message) {
      commit("setMessage", message);
    },

    clearMessage({ commit }) {
      commit("setMessage", null);
    },
  },

  getters: {
    message(state) {
      return state.message;
    },
  },
};
