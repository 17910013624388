<template>
  <div>
    <v-card width="100%" class="ma-1">
      <v-navigation-drawer permanent style="width: 100%">
        <v-list dense nav>
          <v-list-item-group>
            <v-list-item :to="{ name: 'admin-dashboard' }">
              <v-list-item-icon>
                <v-icon>mdi-view-dashboard</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Dashboard</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'admin-pv-without-appointment' }">
              <v-list-item-icon>
                <v-icon>mdi-file-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>PV sans rendez-vous</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item :to="{ name: 'admin-my-clients' }">
              <v-list-item-icon>
                <v-icon>mdi-human-male-female</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Mes clients</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("user", {
      connectedUser: "user",
    }),

    collaboratorFirstName() {
      return this.connectedUser.firstName;
    },
    collaboratorLastName() {
      return this.connectedUser.lastName;
    },
  },
};
</script>
