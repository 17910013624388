export default {
    namespaced: true,
  
    state: {
      currentStep: 1,
    },
  
    mutations: {
      incrementStep(state) {
        state.currentStep++;
        if (state.currentStep > 4) {
          state.currentStep = 4;
        }
      },
  
      decrementStep(state) {
        state.currentStep--;
        if (state.currentStep < 1) {
          state.currentStep = 1;
        }
      },
  
      lastStep(state) {
        state.currentStep = 4;
      },
  
      resetStep(state) {
        state.currentStep = 1;
      },
    },
  
    actions: {
      incrementStep({ commit }) {
        commit("incrementStep");
      },
  
      decrementStep({ commit }) {
        commit("decrementStep");
      },
  
      lastStep({ commit }) {
        commit("lastStep");
      },
  
      resetStep({ commit }) {
        commit("resetStep");
      },
    },
  
    getters: {
      step(state) {
        return state.currentStep;
      }, 
    },
  };
  