<template>
  <div>{{ errorMsg }}</div>
</template>

<script>
export default {
  computed: {
    errorMsg() {
      return this.$route.params.msg;
    },
  },
};
</script>
