export default {
  namespaced: true,

  state: {
    params: {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
    },
  },

  mutations: {
    updatePage(state, page) {
      state.params.page = page;
    },

    updateItemsPerPage(state, itemsPerPage) {
      state.params.itemsPerPage = itemsPerPage;
    },

    updateSortBy(state, sortBy) {
      state.params.sortBy = sortBy;
    },

    updateSortDesc(state, sortDesc) {
      state.params.sortDesc = sortDesc;
    },
  },

  actions: {
    updateParams({ commit }, data) {
      if ("page" in data && data.page !== undefined) {
        commit("updatePage", parseInt(data.page));
      }

      if ("itemsPerPage" in data && data.itemsPerPage !== undefined) {
        commit("updateItemsPerPage", parseInt(data.itemsPerPage));
      }

      if ("sortBy" in data && data.sortBy !== undefined) {
        if (Array.isArray(data.sortBy)) {
          commit("updateSortBy", data.sortBy);
        } else {
          commit("updateSortBy", [data.sortBy]);
        }
      }

      if ("sortDesc" in data && data.sortDesc !== undefined) {
        if (Array.isArray(data.sortDesc)) {
          commit("updateSortDesc", data.sortDesc);
        } else {
          commit("updateSortDesc", [data.sortDesc === "true"]);
        }
      }
    },

    resetConfig({ dispatch }, data) {
      dispatch("updateParams", data);
    },
  },

  getters: {
    params(state) {
      return state.params;
    },

    paramsForQueryString(state) {
      const obj = {};

      obj.page = state.params.page;
      obj.itemsPerPage = state.params.itemsPerPage;

      if (state.params.sortBy.length === 1) {
        obj.sortBy = state.params.sortBy[0];
      }

      if (state.params.sortDesc.length === 1) {
        obj.sortDesc = state.params.sortDesc[0];
      }

      return obj;
    },
  },
};
