<template>
  <v-card>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-form>
          <p class="text-h5">Confirmation</p>
          <p>
            <span class="font-weight-bold">Nom: </span
            >{{ appointment.lastName }}
          </p>
          <p>
            <span class="font-weight-bold">Prénom: </span
            >{{ appointment.firstName }}
          </p>
          <p>
            <span class="font-weight-bold">E-mail: </span
            >{{ appointment.email }}
          </p>
          <p>
            <span class="font-weight-bold">Rendez-vous: </span
            >{{ appointmentTime }}
          </p>
          <p>
            <span class="font-weight-bold">Motif: </span
            >{{ motivationByValue(appointment.motivation) }}
          </p>
          <div class="d-flex justify-center">
            <v-btn
              class="button-shadow"
              color="primary"
              :disabled="btnBackDisabled"
              @click="prev"
            >
              Précédent
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="button-shadow"
              color="primary"
              @click="createAppointment"
            >
              Envoyer
            </v-btn>
          </div>
        </v-form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import router from "@/router";
import { formatDate, formatTime } from "@/utils/date";

export default {
  data() {
    return {
      btnBackDisabled: false,
    };
  },

  computed: {
    ...mapGetters("appointmentForm", {
      currentStep: "step",
    }),

    ...mapGetters("appointment", ["appointment"]),

    ...mapGetters("commonReportForm", ["motivationByValue"]),

    appointmentTime() {
      const date = formatDate(this.appointment.slot.start, {
        weekday: "long",
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });

      const startTime = formatTime(this.appointment.slot.start);

      const endTime = formatTime(this.appointment.slot.end);

      return `${date} de ${startTime} à ${endTime}`;
    },
  },

  methods: {
    ...mapActions("appointment", ["save"]),

    ...mapActions("appointmentForm", ["resetStep", "decrementStep"]),

    formatDate,
    formatTime,

    prev() {
      this.decrementStep();
    },

    async createAppointment() {
      this.btnBackDisabled = true;

      await this.save();

      this.resetStep();

      router.push({ name: "booking-confirmed" });
    },
  },
};
</script>
