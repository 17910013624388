<template>
  <appReportStepper
    title="PV de la Consultation Psychologique"
    personalDataComponentName="PsychologicalReportPersonalData"
    academicDataComponentName="PsychologicalReportAcademicData"
    appointmentDataComponentName="PsychologicalReportAppointmentData"
    consultationDataComponentName="PsychologicalReportConsultationData"
    reportType="psychology"
  ></appReportStepper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ReportStepper from "@/components/Report/Common/ReportStepper";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters("psychologyReport", ["report"]),
  },

  methods: {
    ...mapActions("psychologyReport", ["loadPreFilledReport"]),
    ...mapActions("commonReportForm", { formWritable: "writable" }),
  },

  async created() {
    const appointmentDateTime = this.$route.query.datetime || null;
    const clientEmail = this.$route.query.email || null;

    if (appointmentDateTime === null || clientEmail === null) {
      throw new Error(
        "La date du rendez-vous et/ou l'adresse email du client sont oblogatoires !"
      );
    }

    this.formWritable();

    // One checks if a previous report exists
    await this.loadPreFilledReport({
      clientEmail: clientEmail,
      appointmentDateTime: appointmentDateTime,
    });
  },

  components: {
    appReportStepper: ReportStepper,
  },
};
</script>
