<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Données personnelles du Client</p>
          </v-card-title>

          <v-select
            :items="gendersList"
            item-text="text"
            item-value="value"
            v-model="clientGenderModel"
            label="Genre *"
            :disabled="unilMember || !isFormWritable"
            :error-messages="validationErrors('clientGenderModel')"
            @change="$v.clientGenderModel.$touch()"
            @blur="$v.clientGenderModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-text-field
            v-model="clientLastNameModel"
            label="Nom de famille *"
            :disabled="unilMember || !isFormWritable"
            :error-messages="validationErrors('clientLastNameModel')"
            @change="$v.clientLastNameModel.$touch()"
            @blur="$v.clientLastNameModel.$touch()"
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="clientFirstNameModel"
            label="Prénom *"
            :disabled="unilMember || !isFormWritable"
            :error-messages="validationErrors('clientFirstNameModel')"
            @change="$v.clientFirstNameModel.$touch()"
            @blur="$v.clientGenderModel.$touch()"
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="clientPernumModel"
            label="Pernum"
            disabled
            class="px-5"
            v-if="unilMember"
          ></v-text-field>

          <v-menu
            v-model="menuDatePicker"
            :close-on-content-click="false"
            max-width="290"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="computedDateFormattedMomentjs"
                clearable
                label="Date de naissance"
                readonly
                :disabled="unilMember || !isFormWritable"
                v-bind="attrs"
                v-on="on"
                @click:clear="clientBirthDateModel = null"
                class="mb-4 px-5"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="clientBirthDateModel"
              locale="fr"
              @change="menuDatePicker = false"
            ></v-date-picker>
          </v-menu>

          <v-select
            :items="countryList"
            item-text="countryName"
            item-value="countryShortCode"
            v-model="clientNationalityModel"
            :disabled="unilMember || !isFormWritable"
            label="Pays d'origine"
            class="mb-4 px-5"
          >
          </v-select>

          <v-text-field
            v-model="clientEmailModel"
            label="Adresse e-mail *"
            :disabled="unilMember || !isFormWritable"
            :error-messages="validationErrors('clientEmailModel')"
            @change="$v.clientEmailModel.$touch()"
            @blur="$v.clientEmailModel.$touch()"
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="clientPhoneModel"
            label="Téléphone"
            :disabled="!isFormWritable"
            class="mb-4 px-5"
          ></v-text-field>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { formatDate } from "@/utils/date";
import countries from "@/data/countries";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  props: {
    reportType: { type: String, required: true },
  },

  data() {
    return {
      menuDatePicker: false,
      clientGenderModel: null,
      clientFirstNameModel: null,
      clientLastNameModel: null,
      clientPernumModel: null,
      clientBirthDateModel: null,
      clientNationalityModel: null,
      clientEmailModel: null,
      clientPhoneModel: null,
      countryModel: "",
    };
  },

  computed: {
    ...mapState({
      report(state, getters) {
        return getters[this.reportType + "Report/report"];
      },
      reportExists(state, getters) {
        return getters[this.reportType + "Report/reportExists"];
      },
      unilMember(state, getters) {
        return getters[this.reportType + "Report/unilMember"];
      },
    }),

    ...mapGetters("commonReportForm", ["gendersList", "isFormWritable"]),

    countryList() {
      let frCountries = countries
        .map((item) => {
          return {
            countryName: this.$t(item.countryName),
            countryShortCode: item.countryShortCode,
          };
        })
        .sort((a, b) =>
          a.countryName > b.countryName
            ? 1
            : b.countryName > a.countryName
            ? -1
            : 0
        );

      frCountries.unshift(
        { countryName: "Suisse", countryShortCode: "CH" },
        { countryName: "France", countryShortCode: "FR" },
        { divider: true }
      );

      return frCountries;
    },

    computedDateFormattedMomentjs() {
      if (!this.clientBirthDateModel) {
        return "";
      }

      return formatDate(new Date(this.clientBirthDateModel));
    },

    rules() {
      return {
        clientGenderModel: { required },
        clientFirstNameModel: { required },
        clientLastNameModel: { required },
        clientEmailModel: { required, email },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "clientGenderModel") {
          if (this.$v.clientGenderModel.$dirty) {
            !this.$v.clientGenderModel.required &&
              errors.push("Le genre est requis !");
          }
          return errors;
        }

        if (field === "clientFirstNameModel") {
          if (this.$v.clientFirstNameModel.$dirty) {
            !this.$v.clientFirstNameModel.required &&
              errors.push("Le prénom est requis !");
          }
          return errors;
        }

        if (field === "clientLastNameModel") {
          if (this.$v.clientLastNameModel.$dirty) {
            !this.$v.clientLastNameModel.required &&
              errors.push("Le nom de famille est requis !");
          }
          return errors;
        }

        if (field === "clientEmailModel") {
          if (this.$v.clientEmailModel.$dirty) {
            !this.$v.clientEmailModel.email &&
              errors.push("L'adresse e-mail doit être valide !");
            !this.$v.clientEmailModel.required &&
              errors.push("L'adresse e-mail' est requise !");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions({
      updateObjReport(dispatch, payload) {
        return dispatch(this.reportType + "Report/updateObjReport", payload);
      },
    }),

    ...mapActions("commonReportForm", [
      "incrementStep",
      "lastStep",
      "resetStep",
    ]),

    formatDate,

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjReport({
        gender: this.clientGenderModel,
        firstName: this.clientFirstNameModel,
        lastName: this.clientLastNameModel,
        birthDate: this.clientBirthDateModel,
        nationality: this.clientNationalityModel,
        email: this.clientEmailModel,
        phone: this.clientPhoneModel,
      });

      this.incrementStep();
    },
  },

  created() {
    this.clientGenderModel = this.report.gender;
    this.clientFirstNameModel = this.report.firstName;
    this.clientLastNameModel = this.report.lastName;
    this.clientPernumModel = this.report.userId;
    this.clientBirthDateModel = this.report.birthDate;
    this.clientNationalityModel = this.report.nationality;
    this.clientEmailModel = this.report.email;
    this.clientPhoneModel = this.report.phone;

    this.resetStep();
    if (this.isFormWritable && this.reportExists) {
      this.lastStep();
    }
  },
};
</script>
