import axios from "axios";
import moment from "moment-timezone";
import { createDateTimeTz } from "@/utils/date";

export default {
  namespaced: true,

  state: {
    slots: [],

    isLoading: false,
  },

  mutations: {
    setSlots(state, slots) {
      state.slots = slots;
    },

    loading(state) {
      state.isLoading = true;
    },

    loaded(state) {
      state.isLoading = false;
    },
  },

  actions: {
    async loadSlots({ commit }, date) {
      commit("loading");
      const momentDate = moment(date);

      const response = await axios.get(
        "/permanency/slots?date=" + momentDate.format("YYYY-MM-DD")
      );

      const slots = [];
      const data = response.data;

      data.forEach((slot) => {
        const start = createDateTimeTz(slot.start);
        const end = createDateTimeTz(slot.end);

        slots.push({ start, end });
      });

      commit("setSlots", slots);
      commit("loaded");
    },
  },

  getters: {
    slots(state) {
      return state.slots;
    },

    isLoading(state) {
      return state.isLoading;
    },
  },
};
