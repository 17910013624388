import Vue from "vue";
import Vuex from "vuex";

import layout from "./module/layout";
import dataTable from "./module/dataTable";
import user from "./module/user";
import person from "./module/person";
import appointment from "./module/Appointment/Permanency/appointment";
import appointmentForm from "./module/Appointment/Permanency/appointmentForm";
import slot from "./module/Appointment/Permanency/slot";
import commonReport from "./module/Common/report";
import commonReportForm from "./module/Common/reportForm";
import permanencyReport from "./module/Appointment/Permanency/report";
import psychologyReport from "./module/Appointment/Psychological/report";
import psychologyReportForm from "./module/Appointment/Psychological/reportForm";
import adminDashboard from "./module/Admin/dasboard";
import adminMyClients from "./module/Admin/myClients";
import adminReportList from "./module/Admin/reportList";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    layout,
    dataTable,
    user,
    person,
    appointment,
    appointmentForm,
    slot,
    commonReport,
    commonReportForm,
    permanencyReport,
    psychologyReport,
    psychologyReportForm,
    adminDashboard,
    adminMyClients,
    adminReportList,
  },
});
