import axios from "@/axios-auth";
import moment from "moment";
import { isObjectEmpty } from "@/utils/object";

export default {
  namespaced: true,

  state: {
    report: {
      id: null,
      phone: null,
      gender: null,
      lastName: null,
      firstName: null,
      userId: null,
      birthDate: null,
      nationality: null,
      email: null,
      situation: [],
      priorExperience: null,
      admission: null,
      clientCategory: null,
      levelOfStudy: null,
      principalFaculty: null,
      studies: null,
      studyPlan: null,
      degreeType: null,
      dateTime: null,
      typology: null,
      typologyOther: null,
      motivation: null,
      socCollaboratorId: null,
      socCollaboratorFirstName: null,
      socCollaboratorLastName: null,
      socCollaboratorComments: null,
      themes: [],
      socCollaboratorDecision: null,
    },
  },

  mutations: {
    updateObjReport(state, newData) {
      Object.keys(state.report).forEach((attr) => {
        if (Object.prototype.hasOwnProperty.call(newData, attr)) {
          state.report[attr] = newData[attr];
        }
      });
    },
  },

  actions: {
    updateObjReport({ commit }, newData) {
      commit("updateObjReport", newData);
    },

    async loadById({ commit }, id) {
      commit("commonReport/loading", {}, { root: true });

      let response = await axios.get(`appointments/report/${id}`);

      commit("updateObjReport", response.data);
      commit("commonReport/loaded", {}, { root: true });
    },

    async loadPreFilledReport({ dispatch, commit }, appointmentId) {
      commit("commonReport/loading", {}, { root: true });

      try {
        let response = await axios.get(`/appointments/report/${appointmentId}`);

        const preFilledReport = { ...response.data };

        commit("updateObjReport", preFilledReport);
        commit("commonReport/loaded", {}, { root: true });
      } catch (error) {
        if (error.response.status === 404) {
          const appointment = await dispatch(
            "appointment/loadAppointment",
            appointmentId,
            { root: true }
          );

          if (isObjectEmpty(appointment)) {
            throw new Error(
              `Il n'existe aucun rendez-vous correspondant à l'identifiant ${appointmentId}`
            );
          }

          const preFilledReport = {
            id: appointmentId,
            firstName: appointment.firstName,
            lastName: appointment.lastName,
            email: appointment.email,
            dateTime: appointment.slot.start,
            socCollaboratorId: this.getters[`user/user`].pernum,
            socCollaboratorFirstName: this.getters[`user/user`].firstName,
            socCollaboratorLastName: this.getters[`user/user`].lastName,
          };

          if (appointment.pernum !== null) {
            let person = await dispatch(
              "person/searchByPernum",
              appointment.pernum,
              {
                root: true,
              }
            );

            if (!isObjectEmpty(person)) {
              const birthDate = moment(person.birthDate, "DD-MM-YYYY");

              Object.assign(preFilledReport, {
                gender: person.genderType,
                firstName: person.firstName,
                lastName: person.lastName,
                birthDate: birthDate.format("YYYY-MM-DD"),
                userId: person.personNumber,
                nationality: person.nativeCountry,
                clientCategory: person.categories[0].category,
                levelOfStudy: person.levelofstudy,
                principalFaculty: person.principalFaculty,
                studies: person.studyProgram,
                studyPlan: person.studyPlan,
              });
            }
          }

          commit("updateObjReport", preFilledReport);

          commit("commonReport/loaded", {}, { root: true });
        } else {
          throw error;
        }
      }
    },

    async save({ getters, commit }) {
      commit("commonReport/saving", {}, { root: true });

      const payload = Object.assign({}, getters.report);

      let response = await axios.post("/appointments/report", payload);

      commit("commonReport/saved", {}, { root: true });

      return response;
    },
  },

  getters: {
    report(state) {
      return state.report;
    },

    unilMember(state) {
      return state.report.userId !== null;
    },
  },
};
