export default [
  {
    "countryName": "Afghanistan",
    "countryShortCode": "AF"
  },
  {
    "countryName": "Åland Islands",
    "countryShortCode": "AX"
  },
  {
    "countryName": "Albania",
    "countryShortCode": "AL"
  },
  {
    "countryName": "Algeria",
    "countryShortCode": "DZ"
  },
  {
    "countryName": "American Samoa",
    "countryShortCode": "AS"
  },
  {
    "countryName": "Andorra",
    "countryShortCode": "AD"
  },
  {
    "countryName": "Angola",
    "countryShortCode": "AO"
  },
  {
    "countryName": "Anguilla",
    "countryShortCode": "AI"
  },
  {
    "countryName": "Antarctica",
    "countryShortCode": "AQ"
  },
  {
    "countryName": "Antigua and Barbuda",
    "countryShortCode": "AG"
  },
  {
    "countryName": "Argentina",
    "countryShortCode": "AR"
  },
  {
    "countryName": "Armenia",
    "countryShortCode": "AM"
  },
  {
    "countryName": "Aruba",
    "countryShortCode": "AW"
  },
  {
    "countryName": "Australia",
    "countryShortCode": "AU"
  },
  {
    "countryName": "Austria",
    "countryShortCode": "AT"
  },
  {
    "countryName": "Azerbaijan",
    "countryShortCode": "AZ"
  },
  {
    "countryName": "Bahamas",
    "countryShortCode": "BS"
  },
  {
    "countryName": "Bahrain",
    "countryShortCode": "BH"
  },
  {
    "countryName": "Bangladesh",
    "countryShortCode": "BD"
  },
  {
    "countryName": "Barbados",
    "countryShortCode": "BB"
  },
  {
    "countryName": "Belarus",
    "countryShortCode": "BY"
  },
  {
    "countryName": "Belgium",
    "countryShortCode": "BE"
  },
  {
    "countryName": "Belize",
    "countryShortCode": "BZ"
  },
  {
    "countryName": "Benin",
    "countryShortCode": "BJ"
  },
  {
    "countryName": "Bermuda",
    "countryShortCode": "BM"
  },
  {
    "countryName": "Bhutan",
    "countryShortCode": "BT"
  },
  {
    "countryName": "Bolivia",
    "countryShortCode": "BO"
  },
  {
    "countryName": "Bonaire, Sint Eustatius and Saba",
    "countryShortCode": "BQ"
  },
  {
    "countryName": "Bosnia and Herzegovina",
    "countryShortCode": "BA"
  },
  {
    "countryName": "Botswana",
    "countryShortCode": "BW"
  },
  {
    "countryName": "Bouvet Island",
    "countryShortCode": "BV"
  },
  {
    "countryName": "Brazil",
    "countryShortCode": "BR"
  },
  {
    "countryName": "British Indian Ocean Territory",
    "countryShortCode": "IO"
  },
  {
    "countryName": "Brunei Darussalam",
    "countryShortCode": "BN"
  },
  {
    "countryName": "Bulgaria",
    "countryShortCode": "BG"
  },
  {
    "countryName": "Burkina Faso",
    "countryShortCode": "BF"
  },
  {
    "countryName": "Burundi",
    "countryShortCode": "BI"
  },
  {
    "countryName": "Cambodia",
    "countryShortCode": "KH"
  },
  {
    "countryName": "Cameroon",
    "countryShortCode": "CM"
  },
  {
    "countryName": "Canada",
    "countryShortCode": "CA"
  },
  {
    "countryName": "Cape Verde",
    "countryShortCode": "CV"
  },
  {
    "countryName": "Cayman Islands",
    "countryShortCode": "KY"
  },
  {
    "countryName": "Central African Republic",
    "countryShortCode": "CF"
  },
  {
    "countryName": "Chad",
    "countryShortCode": "TD"
  },
  {
    "countryName": "Chile",
    "countryShortCode": "CL"
  },
  {
    "countryName": "China",
    "countryShortCode": "CN"
  },
  {
    "countryName": "Christmas Island",
    "countryShortCode": "CX"
  },
  {
    "countryName": "Cocos (Keeling) Islands",
    "countryShortCode": "CC"
  },
  {
    "countryName": "Colombia",
    "countryShortCode": "CO"
  },
  {
    "countryName": "Comoros",
    "countryShortCode": "KM"
  },
  {
    "countryName": "Congo, Republic of the (Brazzaville)",
    "countryShortCode": "CG"
  },
  {
    "countryName": "Congo, the Democratic Republic of the (Kinshasa)",
    "countryShortCode": "CD"
  },
  {
    "countryName": "Cook Islands",
    "countryShortCode": "CK"
  },
  {
    "countryName": "Costa Rica",
    "countryShortCode": "CR"
  },
  {
    "countryName": "Côte d'Ivoire, Republic of",
    "countryShortCode": "CI"
  },
  {
    "countryName": "Croatia",
    "countryShortCode": "HR"
  },
  {
    "countryName": "Cuba",
    "countryShortCode": "CU"
  },
  {
    "countryName": "Curaçao",
    "countryShortCode": "CW"
  },
  {
    "countryName": "Cyprus",
    "countryShortCode": "CY"
  },
  {
    "countryName": "Czech Republic",
    "countryShortCode": "CZ"
  },
  {
    "countryName": "Denmark",
    "countryShortCode": "DK"
  },
  {
    "countryName": "Djibouti",
    "countryShortCode": "DJ"
  },
  {
    "countryName": "Dominica",
    "countryShortCode": "DM"
  },
  {
    "countryName": "Dominican Republic",
    "countryShortCode": "DO"
  },
  {
    "countryName": "Ecuador",
    "countryShortCode": "EC"
  },
  {
    "countryName": "Egypt",
    "countryShortCode": "EG"
  },
  {
    "countryName": "El Salvador",
    "countryShortCode": "SV"
  },
  {
    "countryName": "Equatorial Guinea",
    "countryShortCode": "GQ"
  },
  {
    "countryName": "Eritrea",
    "countryShortCode": "ER"
  },
  {
    "countryName": "Estonia",
    "countryShortCode": "EE"
  },
  {
    "countryName": "Ethiopia",
    "countryShortCode": "ET"
  },
  {
    "countryName": "Falkland Islands (Islas Malvinas)",
    "countryShortCode": "FK"
  },
  {
    "countryName": "Faroe Islands",
    "countryShortCode": "FO"
  },
  {
    "countryName": "Fiji",
    "countryShortCode": "FJ"
  },
  {
    "countryName": "Finland",
    "countryShortCode": "FI"
  },
  {
    "countryName": "France",
    "countryShortCode": "FR"
  },
  {
    "countryName": "French Guiana",
    "countryShortCode": "GF"
  },
  {
    "countryName": "French Polynesia",
    "countryShortCode": "PF"
  },
  {
    "countryName": "French Southern and Antarctic Lands",
    "countryShortCode": "TF"
  },
  {
    "countryName": "Gabon",
    "countryShortCode": "GA"
  },
  {
    "countryName": "Gambia, The",
    "countryShortCode": "GM"
  },
  {
    "countryName": "Georgia",
    "countryShortCode": "GE"
  },
  {
    "countryName": "Germany",
    "countryShortCode": "DE"
  },
  {
    "countryName": "Ghana",
    "countryShortCode": "GH"
  },
  {
    "countryName": "Gibraltar",
    "countryShortCode": "GI"
  },
  {
    "countryName": "Greece",
    "countryShortCode": "GR"
  },
  {
    "countryName": "Greenland",
    "countryShortCode": "GL"
  },
  {
    "countryName": "Grenada",
    "countryShortCode": "GD"
  },
  {
    "countryName": "Guadeloupe",
    "countryShortCode": "GP"
  },
  {
    "countryName": "Guam",
    "countryShortCode": "GU"
  },
  {
    "countryName": "Guatemala",
    "countryShortCode": "GT"
  },
  {
    "countryName": "Guernsey",
    "countryShortCode": "GG"
  },
  {
    "countryName": "Guinea",
    "countryShortCode": "GN"
  },
  {
    "countryName": "Guinea-Bissau",
    "countryShortCode": "GW"
  },
  {
    "countryName": "Guyana",
    "countryShortCode": "GY"
  },
  {
    "countryName": "Haiti",
    "countryShortCode": "HT"
  },
  {
    "countryName": "Heard Island and McDonald Islands",
    "countryShortCode": "HM"
  },
  {
    "countryName": "Holy See (Vatican City)",
    "countryShortCode": "VA"
  },
  {
    "countryName": "Honduras",
    "countryShortCode": "HN"
  },
  {
    "countryName": "Hong Kong",
    "countryShortCode": "HK"
  },
  {
    "countryName": "Hungary",
    "countryShortCode": "HU"
  },
  {
    "countryName": "Iceland",
    "countryShortCode": "IS"
  },
  {
    "countryName": "India",
    "countryShortCode": "IN"
  },
  {
    "countryName": "Indonesia",
    "countryShortCode": "ID"
  },
  {
    "countryName": "Iran, Islamic Republic of",
    "countryShortCode": "IR"
  },
  {
    "countryName": "Iraq",
    "countryShortCode": "IQ"
  },
  {
    "countryName": "Ireland",
    "countryShortCode": "IE"
  },
  {
    "countryName": "Isle of Man",
    "countryShortCode": "IM"
  },
  {
    "countryName": "Israel",
    "countryShortCode": "IL"
  },
  {
    "countryName": "Italy",
    "countryShortCode": "IT"
  },
  {
    "countryName": "Jamaica",
    "countryShortCode": "JM"
  },
  {
    "countryName": "Japan",
    "countryShortCode": "JP"
  },
  {
    "countryName": "Jersey",
    "countryShortCode": "JE"
  },
  {
    "countryName": "Jordan",
    "countryShortCode": "JO"
  },
  {
    "countryName": "Kazakhstan",
    "countryShortCode": "KZ"
  },
  {
    "countryName": "Kenya",
    "countryShortCode": "KE"
  },
  {
    "countryName": "Kiribati",
    "countryShortCode": "KI"
  },
  {
    "countryName": "Korea, Democratic People's Republic of",
    "countryShortCode": "KP"
  },
  {
    "countryName": "Korea, Republic of",
    "countryShortCode": "KR"
  },
  {
    "countryName": "Kuwait",
    "countryShortCode": "KW"
  },
  {
    "countryName": "Kyrgyzstan",
    "countryShortCode": "KG"
  },
  {
    "countryName": "Laos",
    "countryShortCode": "LA"
  },
  {
    "countryName": "Latvia",
    "countryShortCode": "LV"
  },
  {
    "countryName": "Lebanon",
    "countryShortCode": "LB"
  },
  {
    "countryName": "Lesotho",
    "countryShortCode": "LS"
  },
  {
    "countryName": "Liberia",
    "countryShortCode": "LR"
  },
  {
    "countryName": "Libya",
    "countryShortCode": "LY"
  },
  {
    "countryName": "Liechtenstein",
    "countryShortCode": "LI"
  },
  {
    "countryName": "Lithuania",
    "countryShortCode": "LT"
  },
  {
    "countryName": "Luxembourg",
    "countryShortCode": "LU"
  },
  {
    "countryName": "Macao",
    "countryShortCode": "MO"
  },
  {
    "countryName": "Macedonia, Republic of",
    "countryShortCode": "MK"
  },
  {
    "countryName": "Madagascar",
    "countryShortCode": "MG"
  },
  {
    "countryName": "Malawi",
    "countryShortCode": "MW"
  },
  {
    "countryName": "Malaysia",
    "countryShortCode": "MY"
  },
  {
    "countryName": "Maldives",
    "countryShortCode": "MV"
  },
  {
    "countryName": "Mali",
    "countryShortCode": "ML"
  },
  {
    "countryName": "Malta",
    "countryShortCode": "MT"
  },
  {
    "countryName": "Marshall Islands",
    "countryShortCode": "MH"
  },
  {
    "countryName": "Martinique",
    "countryShortCode": "MQ"
  },
  {
    "countryName": "Mauritania",
    "countryShortCode": "MR"
  },
  {
    "countryName": "Mauritius",
    "countryShortCode": "MU"
  },
  {
    "countryName": "Mayotte",
    "countryShortCode": "YT"
  },
  {
    "countryName": "Mexico",
    "countryShortCode": "MX"
  },
  {
    "countryName": "Micronesia, Federated States of",
    "countryShortCode": "FM"
  },
  {
    "countryName": "Moldova",
    "countryShortCode": "MD"
  },
  {
    "countryName": "Monaco",
    "countryShortCode": "MC"
  },
  {
    "countryName": "Mongolia",
    "countryShortCode": "MN"
  },
  {
    "countryName": "Montenegro",
    "countryShortCode": "ME"
  },
  {
    "countryName": "Montserrat",
    "countryShortCode": "MS"
  },
  {
    "countryName": "Morocco",
    "countryShortCode": "MA"
  },
  {
    "countryName": "Mozambique",
    "countryShortCode": "MZ"
  },
  {
    "countryName": "Myanmar",
    "countryShortCode": "MM"
  },
  {
    "countryName": "Namibia",
    "countryShortCode": "NA"
  },
  {
    "countryName": "Nauru",
    "countryShortCode": "NR"
  },
  {
    "countryName": "Nepal",
    "countryShortCode": "NP"
  },
  {
    "countryName": "Netherlands",
    "countryShortCode": "NL"
  },
  {
    "countryName": "New Caledonia",
    "countryShortCode": "NC"
  },
  {
    "countryName": "New Zealand",
    "countryShortCode": "NZ"
  },
  {
    "countryName": "Nicaragua",
    "countryShortCode": "NI"
  },
  {
    "countryName": "Niger",
    "countryShortCode": "NE"
  },
  {
    "countryName": "Nigeria",
    "countryShortCode": "NG"
  },
  {
    "countryName": "Niue",
    "countryShortCode": "NU"
  },
  {
    "countryName": "Norfolk Island",
    "countryShortCode": "NF"
  },
  {
    "countryName": "Northern Mariana Islands",
    "countryShortCode": "MP"
  },
  {
    "countryName": "Norway",
    "countryShortCode": "NO"
  },
  {
    "countryName": "Oman",
    "countryShortCode": "OM"
  },
  {
    "countryName": "Pakistan",
    "countryShortCode": "PK"
  },
  {
    "countryName": "Palau",
    "countryShortCode": "PW"
  },
  {
    "countryName": "Palestine, State of",
    "countryShortCode": "PS"
  },
  {
    "countryName": "Panama",
    "countryShortCode": "PA"
  },
  {
    "countryName": "Papua New Guinea",
    "countryShortCode": "PG"
  },
  {
    "countryName": "Paraguay",
    "countryShortCode": "PY"
  },
  {
    "countryName": "Peru",
    "countryShortCode": "PE"
  },
  {
    "countryName": "Philippines",
    "countryShortCode": "PH"
  },
  {
    "countryName": "Pitcairn",
    "countryShortCode": "PN"
  },
  {
    "countryName": "Poland",
    "countryShortCode": "PL"
  },
  {
    "countryName": "Portugal",
    "countryShortCode": "PT"
  },
  {
    "countryName": "Puerto Rico",
    "countryShortCode": "PR"
  },
  {
    "countryName": "Qatar",
    "countryShortCode": "QA"
  },
  {
    "countryName": "Réunion",
    "countryShortCode": "RE"
  },
  {
    "countryName": "Romania",
    "countryShortCode": "RO"
  },
  {
    "countryName": "Russian Federation",
    "countryShortCode": "RU"
  },
  {
    "countryName": "Rwanda",
    "countryShortCode": "RW"
  },
  {
    "countryName": "Saint Barthélemy",
    "countryShortCode": "BL"
  },
  {
    "countryName": "Saint Helena, Ascension and Tristan da Cunha",
    "countryShortCode": "SH"
  },
  {
    "countryName": "Saint Kitts and Nevis",
    "countryShortCode": "KN"
  },
  {
    "countryName": "Saint Lucia",
    "countryShortCode": "LC"
  },
  {
    "countryName": "Saint Martin",
    "countryShortCode": "MF"
  },
  {
    "countryName": "Saint Pierre and Miquelon",
    "countryShortCode": "PM"
  },
  {
    "countryName": "Saint Vincent and the Grenadines",
    "countryShortCode": "VC"
  },
  {
    "countryName": "Samoa",
    "countryShortCode": "WS"
  },
  {
    "countryName": "San Marino",
    "countryShortCode": "SM"
  },
  {
    "countryName": "Sao Tome and Principe",
    "countryShortCode": "ST"
  },
  {
    "countryName": "Saudi Arabia",
    "countryShortCode": "SA"
  },
  {
    "countryName": "Senegal",
    "countryShortCode": "SN"
  },
  {
    "countryName": "Serbia",
    "countryShortCode": "RS"
  },
  {
    "countryName": "Seychelles",
    "countryShortCode": "SC"
  },
  {
    "countryName": "Sierra Leone",
    "countryShortCode": "SL"
  },
  {
    "countryName": "Singapore",
    "countryShortCode": "SG"
  },
  {
    "countryName": "Sint Maarten (Dutch part)",
    "countryShortCode": "SX"
  },
  {
    "countryName": "Slovakia",
    "countryShortCode": "SK"
  },
  {
    "countryName": "Slovenia",
    "countryShortCode": "SI"
  },
  {
    "countryName": "Solomon Islands",
    "countryShortCode": "SB"
  },
  {
    "countryName": "Somalia",
    "countryShortCode": "SO"
  },
  {
    "countryName": "South Africa",
    "countryShortCode": "ZA"
  },
  {
    "countryName": "South Georgia and South Sandwich Islands",
    "countryShortCode": "GS"
  },
  {
    "countryName": "South Sudan",
    "countryShortCode": "SS"
  },
  {
    "countryName": "Spain",
    "countryShortCode": "ES"
  },
  {
    "countryName": "Sri Lanka",
    "countryShortCode": "LK"
  },
  {
    "countryName": "Sudan",
    "countryShortCode": "SD"
  },
  {
    "countryName": "Suriname",
    "countryShortCode": "SR"
  },
  {
    "countryName": "Swaziland",
    "countryShortCode": "SZ"
  },
  {
    "countryName": "Sweden",
    "countryShortCode": "SE"
  },
  {
    "countryName": "Switzerland",
    "countryShortCode": "CH"
  },
  {
    "countryName": "Syrian Arab Republic",
    "countryShortCode": "SY"
  },
  {
    "countryName": "Taiwan",
    "countryShortCode": "TW"
  },
  {
    "countryName": "Tajikistan",
    "countryShortCode": "TJ"
  },
  {
    "countryName": "Tanzania, United Republic of",
    "countryShortCode": "TZ"
  },
  {
    "countryName": "Thailand",
    "countryShortCode": "TH"
  },
  {
    "countryName": "Timor-Leste",
    "countryShortCode": "TL"
  },
  {
    "countryName": "Togo",
    "countryShortCode": "TG"
  },
  {
    "countryName": "Tokelau",
    "countryShortCode": "TK"
  },
  {
    "countryName": "Tonga",
    "countryShortCode": "TO"
  },
  {
    "countryName": "Trinidad and Tobago",
    "countryShortCode": "TT"
  },
  {
    "countryName": "Tunisia",
    "countryShortCode": "TN"
  },
  {
    "countryName": "Turkey",
    "countryShortCode": "TR"
  },
  {
    "countryName": "Turkmenistan",
    "countryShortCode": "TM"
  },
  {
    "countryName": "Turks and Caicos Islands",
    "countryShortCode": "TC"
  },
  {
    "countryName": "Tuvalu",
    "countryShortCode": "TV"
  },
  {
    "countryName": "Uganda",
    "countryShortCode": "UG"
  },
  {
    "countryName": "Ukraine",
    "countryShortCode": "UA"
  },
  {
    "countryName": "United Arab Emirates",
    "countryShortCode": "AE"
  },
  {
    "countryName": "United Kingdom",
    "countryShortCode": "GB"
  },
  {
    "countryName": "United States",
    "countryShortCode": "US"
  },
  {
    "countryName": "United States Minor Outlying Islands",
    "countryShortCode": "UM"
  },
  {
    "countryName": "Uruguay",
    "countryShortCode": "UY"
  },
  {
    "countryName": "Uzbekistan",
    "countryShortCode": "UZ"
  },
  {
    "countryName": "Vanuatu",
    "countryShortCode": "VU"
  },
  {
    "countryName": "Venezuela, Bolivarian Republic of",
    "countryShortCode": "VE"
  },
  {
    "countryName": "Vietnam",
    "countryShortCode": "VN"
  },
  {
    "countryName": "Virgin Islands, British",
    "countryShortCode": "VG"
  },
  {
    "countryName": "Virgin Islands, U.S.",
    "countryShortCode": "VI"
  },
  {
    "countryName": "Wallis and Futuna",
    "countryShortCode": "WF"
  },
  {
    "countryName": "Western Sahara",
    "countryShortCode": "EH"
  },
  {
    "countryName": "Yemen",
    "countryShortCode": "YE"
  },
  {
    "countryName": "Zambia",
    "countryShortCode": "ZM"
  },
  {
    "countryName": "Zimbabwe",
    "countryShortCode": "ZW"
  }
]

