<template>
  <appReportStepper
    title="PV de la Consultation de Type Permanence"
    personalDataComponentName="PermanencyReportPersonalData"
    academicDataComponentName="PermanencyReportAcademicData"
    appointmentDataComponentName="PermanencyReportAppointmentData"
    consultationDataComponentName="PermanencyReportConsultationData"
    reportType="permanency"
  ></appReportStepper>
</template>

<script>
import { mapActions } from "vuex";
import ReportStepper from "@/components/Report/Common/ReportStepper";

export default {
  data() {
    return {};
  },

  computed: {},

  methods: {
    ...mapActions("permanencyReport", { loadReportById: "loadById" }),
    ...mapActions("commonReportForm", { formReadOnly: "readOnly" }),
  },

  async created() {
    const reportId = this.$route.params.id;

    this.formReadOnly();

    await this.loadReportById(reportId);
  },

  components: {
    appReportStepper: ReportStepper,
  },
};
</script>
