<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="button-shadow"
              color="primary"
              @click="prev"
            >
              Précédent
            </v-btn>
            <v-btn
              class="button-shadow ml-3"
              color="primary"
              :disabled="!isFormWritable"
              @click="save"
            >
              Sauvegarder
            </v-btn>
          </v-card-title>
        </v-card>

        <v-alert
          type="success"
          dismissible
          class="ma-1"
          v-if="message !== null"
        >
          {{ message }}
        </v-alert>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Consultation de Type Permanence</p>
          </v-card-title>

          <v-select
            v-model="themesModel"
            :items="themesByAudience('permanency')"
            item-text="text"
            item-value="value"
            label="Thématiques abordées"
            :disabled="!isFormWritable"
            multiple
            chips
            class="mb-4 px-5"
          ></v-select>

          <v-textarea
            v-model="commentModel"
            class="pt-1 mb-4 px-5"
            label="Commentaires *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('commentModel')"
            @change="$v.commentModel.$touch()"
            @blur="$v.commentModel.$touch()"
            auto-grow
            :solo="validationErrors('commentModel').length === 0"
          ></v-textarea>

          <v-select
            v-model="followUpDecisionModel"
            :items="followUpDecisionsByAudience('permanency')"
            item-text="text"
            item-value="value"
            label="Décision sur la suite de la consultation *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('followUpDecisionModel')"
            @change="$v.followUpDecisionModel.$touch()"
            @blur="$v.followUpDecisionModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-text-field
            v-model="socCollaboratorLastNameModel"
            label="Nom de famille du collaborateur SOC"
            disabled
            class="mb-4 px-5"
          ></v-text-field>

          <v-text-field
            v-model="socCollaboratorFirstNameModel"
            label="Prénom du collaborateur SOC"
            disabled
            class="mb-4 px-5"
          ></v-text-field>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
              class="button-shadow"
              color="primary"
              @click="prev"
            >
              Précédent
            </v-btn>
            <v-btn
              class="button-shadow ml-3"
              color="primary"
              :disabled="!isFormWritable"
              @click="save"
            >
              Sauvegarder
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      themesModel: [],
      commentModel: null,
      followUpDecisionModel: null,
      socCollaboratorFirstNameModel: null,
      socCollaboratorLastNameModel: null,
    };
  },
  computed: {
    ...mapGetters("permanencyReport", ["report"]),

    ...mapGetters("commonReportForm", [
      "isFormWritable",
      "themesByAudience",
      "followUpDecisionsByAudience",
    ]),

    ...mapGetters("layout", ["message"]),

    rules() {
      return {
        commentModel: { required },
        followUpDecisionModel: { required },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "commentModel") {
          if (this.$v.commentModel.$dirty) {
            !this.$v.commentModel.required &&
              errors.push("Le commentaire est requise !");
          }
          return errors;
        }

        if (field === "followUpDecisionModel") {
          if (this.$v.followUpDecisionModel.$dirty) {
            !this.$v.followUpDecisionModel.required &&
              errors.push(
                "La décision sur la suite de la consultation est requise !"
              );
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("permanencyReport", {
      updateReport: "updateObjReport",
      saveReport: "save",
    }),

    ...mapActions("layout", ["setMessage"]),

    ...mapActions("commonReportForm", [
      "decrementStep",
      "resetStep",
      "readOnly",
    ]),

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    prev() {
      this.decrementStep();
    },

    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateReport({
        themes: this.themesModel,
        socCollaboratorComments: this.commentModel,
        socCollaboratorDecision: this.followUpDecisionModel,
      });

      await this.saveReport();
      this.readOnly();
      this.setMessage("Le rapport a bien été enregistré");
    },
  },

  created() {
    this.themesModel = this.report.themes;
    this.commentModel = this.report.socCollaboratorComments;
    this.followUpDecisionModel = this.report.socCollaboratorDecision;
    this.socCollaboratorFirstNameModel = this.report.socCollaboratorFirstName;
    this.socCollaboratorLastNameModel = this.report.socCollaboratorLastName;
  },
};
</script>
