import axios from "axios";
import axiosAuth from "@/axios-auth";
import { convertJsDateToDbDate } from "@/utils/date";

export default {
  namespaced: true,

  state: {
    appointment: {
      slot: {
        start: null,
        end: null,
      },
      firstName: null,
      lastName: null,
      email: null,
      pernum: null,
      motivation: null,
    },

    isSaving: false,
  },

  mutations: {
    updateObjAppointment(state, newData) {
      Object.keys(state.appointment).forEach((attr) => {
        if (Object.prototype.hasOwnProperty.call(newData, attr)) {
          state.appointment[attr] = newData[attr];
        }
      });
    },

    clearAppointmentObj(state) {
      state.appointment = {
        slot: {
          start: null,
          end: null,
        },
        firstName: "",
        lastName: "",
        email: "",
        pernum: null,
        motivation: "",
      };
    },

    saving(state) {
      state.isSaving = true;
    },

    saved(state) {
      state.isSaving = false;
    },
  },

  actions: {
    async save({ getters, commit }) {
      commit("saving");

      const payload = Object.assign({}, getters.appointment);

      payload.slot = {
        start: convertJsDateToDbDate(payload.slot.start),
        end: convertJsDateToDbDate(payload.slot.end),
      };

      await axios.post("/permanency/book", payload);

      commit("saved");
    },

    resetAppointmentObj({ commit }) {
      commit("clearAppointmentObj");
    },

    async loadAppointment({ commit }, id) {
      try {
        let response = await axiosAuth.get(`/appointments/${id}`);

        const appointment = { ...response.data };
        commit("updateObjAppointment", appointment);
        
        return appointment;
      } catch (error) {
        if (error.response.status === 404) {
          return {};
        }
      }
    },

    updateObjAppointment({ commit }, newData) {
      commit("updateObjAppointment", newData);
    },
  },

  getters: {
    appointment(state) {
      return state.appointment;
    },

    isSaving(state) {
      return state.isSaving;
    },
  },
};
