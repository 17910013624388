export default {
  namespaced: true,

  state: {
    isLoading: false,

    isSaving: false,
  },

  mutations: {
    loading(state) {
      state.isLoading = true;
    },

    loaded(state) {
      state.isLoading = false;
    },

    saving(state) {
      state.isSaving = true;
    },

    saved(state) {
      state.isSaving = false;
    },
  },

  actions: {},

  getters: {
    isLoading(state) {
      return state.isLoading;
    },

    isSaving(state) {
      return state.isSaving;
    },
  },
};
