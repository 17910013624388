<template>
  <div></div>
</template>

<script>
let forceLoad = true;

export default {
  beforeCreate() {
    if (this.$store.getters[`user/isAuthenticated`]) {
      forceLoad = false;
      if (this.$route.query.routeName) {
        this.$router.push({ name: this.$route.query.routeName });
      } else if (this.$route.query.routePath) {
        this.$router.push({ path: this.$route.query.routePath });
      }
    }
  },

  created() {
    if (forceLoad) {
      this.$router.go();
    }
  },
};
</script>
