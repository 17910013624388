<template>
  <v-container fluid class="booking mt-n12 mx-1">
    <v-overlay :value="isLoading || isSaving">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <p class="text-h4 text-center mb-10">
      {{ title }}
    </p>
    <v-row>
      <v-col cols="12">
        <v-stepper v-model="currentStep" class="transparent elevation-0">
          <v-card class="mb-5">
            <v-stepper-header>
              <v-stepper-step :complete="stepCompleted(1)" step="1"
                >Données personnelles du client</v-stepper-step
              >

              <v-divider></v-divider>
              <v-stepper-step :complete="stepCompleted(2)" step="2"
                >Données académiques du client</v-stepper-step
              >
              <v-divider></v-divider>

              <v-stepper-step :complete="stepCompleted(3)" step="3"
                >Informations sur le rendez-vous</v-stepper-step
              >
              <v-divider></v-divider>

              <v-stepper-step step="4"
                >Consultation</v-stepper-step
              >
            </v-stepper-header>
          </v-card>

          <v-card>
            <v-stepper-items>
              <v-stepper-content step="1">
                <component
                  :reportType="reportType"
                  v-bind:is="personalDataComponentName"
                  v-if="!isLoading"
                />
              </v-stepper-content>

              <v-stepper-content step="2">
                <component
                  :reportType="reportType"
                  v-bind:is="academicDataComponentName"
                  v-if="!isLoading && stepCompleted(1)"
                />
              </v-stepper-content>

              <v-stepper-content step="3">
                <component
                  v-bind:is="appointmentDataComponentName"
                  v-if="!isLoading && stepCompleted(2)"
                />
              </v-stepper-content>

              <v-stepper-content step="4">
                <component
                  v-bind:is="consultationDataComponentName"
                  v-if="!isLoading && stepCompleted(3)"
                />
              </v-stepper-content>
            </v-stepper-items>
          </v-card>
        </v-stepper>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import * as permanencyComponents from "@/components/Report/Permanency";
import * as psychologicalCmponents from "@/components/Report/Psychological";

export default {
  props: {
    title: { type: String, required: true },
    personalDataComponentName: { type: String, required: true },
    academicDataComponentName: { type: String, required: true },
    appointmentDataComponentName: { type: String, required: true },
    consultationDataComponentName: { type: String, required: true },
    reportType: { type: String, required: true },
  },

  data() {
    return {};
  },

  computed: {
    ...mapGetters("commonReport", ["isLoading", "isSaving"]),
    ...mapGetters("commonReportForm", {
      currentStep: "step",
    }),
  },

  methods: {
    stepCompleted(stepNumber) {
      return this.currentStep > stepNumber;
    },
  },

  components: {
    ...permanencyComponents,
    ...psychologicalCmponents,
  },
};
</script>
