<template>
  <v-row>
    <v-col cols="12" md="6" offset-md="3">
      <form>
        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>

        <v-card>
          <v-card-title class="pa-5 pb-3">
            <p>Données académiques du client</p>
          </v-card-title>

          <v-select
            v-model="situationModel"
            :items="situationsList"
            item-text="text"
            item-value="value"
            label="Situation actuelle *"
            :disabled="!isFormWritable"
            multiple
            chips
            :error-messages="validationErrors('situationModel')"
            @change="$v.situationModel.$touch()"
            @blur="$v.situationModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-select
            :items="priorExperiencesList"
            v-model="priorExperienceModel"
            label="Parcours préalable du Client *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('priorExperienceModel')"
            @change="$v.priorExperienceModel.$touch()"
            @blur="$v.priorExperienceModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-select
            :items="admissionsList"
            v-model="admissionModel"
            label="Type d'accès à l'UNIL *"
            :disabled="!isFormWritable"
            :error-messages="validationErrors('admissionModel')"
            @change="$v.admissionModel.$touch()"
            @blur="$v.admissionModel.$touch()"
            class="mb-4 px-5"
          ></v-select>

          <v-text-field
            v-model="degreeTypeModel"
            label="Type de diplôme"
            class="mb-4 px-5"
            :disabled="!isFormWritable"
            v-if="!unilMember"
          ></v-text-field>

          <v-text-field
            v-model="clientCategoryModel"
            label="Type de client *"
            disabled
            class="mb-4 px-5"
            v-if="unilMember"
          ></v-text-field>
          <v-text-field
            v-model="clientLevelofstudyModel"
            label="Niveau d'étude *"
            disabled
            class="mb-4 px-5"
            v-if="unilMember && clientCategoryModel === 'STUDENT'"
          ></v-text-field>
          <v-text-field
            v-model="clientPrincipalFacultyModel"
            label="Faculté principale *"
            disabled
            class="mb-4 px-5"
            v-if="unilMember && clientCategoryModel === 'STUDENT'"
          ></v-text-field>
          <v-text-field
            v-model="clientStudiesModel"
            label="Filière *"
            disabled
            class="mb-4 px-5"
            v-if="unilMember && clientCategoryModel === 'STUDENT'"
          ></v-text-field>
          <v-text-field
              v-model="clientStudyPlanModel"
              label="Plan d'étude *"
              disabled
              class="mb-4 px-5"
              v-if="unilMember && clientCategoryModel === 'STUDENT'"
          ></v-text-field>
        </v-card>

        <v-card>
          <v-card-title class="pa-3">
            <v-btn
              outlined
              color="primary"
              @click="back"
              v-if="this.$route.query.origin === 'admin'"
            >
              <v-icon>mdi-history</v-icon> Retour
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="prev">
              Précédent
            </v-btn>
            <v-btn class="button-shadow ml-3" color="primary" @click="next">
              Suivant
            </v-btn>
          </v-card-title>
        </v-card>
      </form>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  props: {
    reportType: { type: String, required: true },
  },

  data() {
    return {
      situationModel: null,
      priorExperienceModel: null,
      admissionModel: null,
      degreeTypeModel: null,
      clientCategoryModel: null,
      clientLevelofstudyModel: null,
      clientPrincipalFacultyModel: null,
      clientStudiesModel: null,
      clientStudyPlanModel: null,
    };
  },

  computed: {
    ...mapState({
      report(state, getters) {
        return getters[this.reportType + "Report/report"];
      },
      unilMember(state, getters) {
        return getters[this.reportType + "Report/unilMember"];
      },
    }),
    ...mapGetters("commonReportForm", [
      "isFormWritable",
      "situationsList",
      "priorExperiencesList",
      "admissionsList",
    ]),

    rules() {
      return {
        situationModel: { required },
        priorExperienceModel: { required },
        admissionModel: { required },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "situationModel") {
          if (this.$v.situationModel.$dirty) {
            !this.$v.situationModel.required &&
              errors.push("La situation actuelle est requise !");
          }
          return errors;
        }

        if (field === "priorExperienceModel") {
          if (this.$v.priorExperienceModel.$dirty) {
            !this.$v.priorExperienceModel.required &&
              errors.push("Le parcours préalable est requis !");
          }
          return errors;
        }

        if (field === "admissionModel") {
          if (this.$v.admissionModel.$dirty) {
            !this.$v.admissionModel.required &&
              errors.push("Le Type d'accès à l'UNIL est requis !");
          }
          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions({
      updateObjReport(dispatch, payload) {
        return dispatch(this.reportType + "Report/updateObjReport", payload);
      },
    }),

    ...mapActions("commonReportForm", [
      "incrementStep",
      "decrementStep",
      "resetStep",
    ]),

    back() {
      this.resetStep();
      this.$router.go(-1);
    },

    prev() {
      this.decrementStep();
    },

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjReport({
        situation: this.situationModel,
        priorExperience: this.priorExperienceModel,
        admission: this.admissionModel,
        degreeType: this.degreeTypeModel,
        clientCategory: this.clientCategoryModel,
        levelOfStudy: this.clientLevelofstudyModel,
        principalFaculty: this.clientPrincipalFacultyModel,
        studies: this.clientStudiesModel,
        studyPlan: this.clientStudyPlanModel,
      });

      this.incrementStep();
    },
  },

  created() {
    this.situationModel = this.report.situation || [];
    this.priorExperienceModel = this.report.priorExperience;
    this.admissionModel = this.report.admission;
    this.degreeTypeModel = this.report.degreeType;
    this.clientCategoryModel = this.report.clientCategory;
    this.clientLevelofstudyModel = this.report.levelOfStudy;
    this.clientPrincipalFacultyModel = this.report.principalFaculty;
    this.clientStudiesModel = this.report.studies;
    this.clientStudyPlanModel = this.report.studyPlan;
  },
};
</script>
