import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Layout/Layout";
import AdminLayout from "@/components/Layout/AdminLayout";
import Home from "@/pages/Home/Home";
import Signin from "@/pages/Auth/Signin";
import Error from "@/pages/Error";
import Booking from "@/pages/Appointment/Permanency/Booking";
import BookingConfirmed from "@/pages/Appointment/Permanency/BookingConfirmed";
import PermanencyReportCreate from "@/pages/Appointment/Permanency/CreateReport";
import PermanencyReportEdit from "@/pages/Appointment/Permanency/EditReport";
import PermanencyReportView from "@/pages/Appointment/Permanency/ViewReport";
import PsychologicalReportCreate from "@/pages/Appointment/Psychological/CreateReport";
import PsychologicalReportEdit from "@/pages/Appointment/Psychological/EditReport";
import PsychologicalReportView from "@/pages/Appointment/Psychological/ViewReport";
import AdminDashboard from "@/pages/Admin/Dashboard";
import AdminPvWithoutAppointment from "@/pages/Admin/PvWithoutAppointment";
import AdminMyClients from "@/pages/Admin/MyClients";
import AdminReportList from "@/pages/Admin/ReportList";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Layout",
    component: Layout,
    redirect: { name: "home" },
    children: [
      {
        path: "home",
        name: "home",
        component: Home,
      },
      {
        path: "signin",
        name: "signin",
        component: Signin,
      },
      {
        path: "error/:msg?",
        name: "error",
        component: Error,
      },
      {
        path: "appointment/permanency/booking",
        name: "permanency-appointment-booking",
        component: Booking,
      },
      {
        path: "appointment/permanency/booking-confirmed",
        name: "booking-confirmed",
        component: BookingConfirmed,
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: AdminLayout,
    redirect: { name: "admin-dashboard" },
    children: [
      {
        path: "dashboard",
        name: "admin-dashboard",
        component: AdminDashboard,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "pv-without-appointment",
        name: "admin-pv-without-appointment",
        component: AdminPvWithoutAppointment,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "my-clients",
        name: "admin-my-clients",
        component: AdminMyClients,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "my-clients/:email/report-list",
        name: "admin-report-list",
        component: AdminReportList,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "appointment/:id/view-report",
        name: "permanency-appointment-view-report",
        component: PermanencyReportView,
        meta: {
          requiresAuth: true,
          roles: ["soc-admin", "soc-psy", "soc-secr"],
        },
      },
      {
        path: "appointment/:id/create-report",
        name: "permanency-appointment-create-report",
        component: PermanencyReportCreate,
        meta: {
          requiresAuth: true,
          roles: ["soc-admin", "soc-psy", "soc-secr"],
        },
      },
      {
        path: "appointment/:id/edit-report",
        name: "permanency-appointment-edit-report",
        component: PermanencyReportEdit,
        meta: {
          requiresAuth: true,
          roles: ["soc-admin", "soc-psy", "soc-secr"],
        },
      },
      {
        path: "psychological-appointment/create-report",
        name: "psychological-appointment-create-report",
        component: PsychologicalReportCreate,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "psychological-appointment/edit-report/:id",
        name: "psychological-appointment-edit-report",
        component: PsychologicalReportEdit,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
      {
        path: "psychological-appointment/view-report/:id",
        name: "psychological-appointment-view-report",
        component: PsychologicalReportView,
        meta: { requiresAuth: true, roles: ["soc-admin", "soc-psy"] },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (Vue.$keycloak.tokenParsed) {
      store.dispatch(
        `user/storeAuthenticationDataFromToken`,
        Vue.$keycloak.tokenParsed
      );

      if (!store.getters[`user/hasRole`](to.meta.roles)) {
        next({
          name: "error",
          params: { msg: "Vous n'êtes pas autorisés à accéder à cette page" },
        });
      }

      next();
    } else {
      next({ name: "signin", query: { routePath: to.fullPath } });
    }
  } else {
    next();
  }
});

export default router;
