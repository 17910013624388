import axios from "@/axios-auth";
import { convertDbDateToJsDate } from "@/utils/date";

export default {
  namespaced: true,

  state: {
    reports: [],

    client: {},

    isLoading: false,
  },

  mutations: {
    setReports(state, reports) {
      state.reports = reports;
    },

    setTotalReports(state, totalReports) {
      state.totalReports = totalReports;
    },

    setClient(state, client) {
      state.client = client;
    },

    loading(state) {
      state.isLoading = true;
    },

    loaded(state) {
      state.isLoading = false;
    },
  },

  actions: {
    async loadReports({ commit }, payload) {
      commit("loading");

      let response = await axios.get("/appointments/reports/light", {
        params: {
          email: payload.email,
        },
      });

      if (response.data.length === 0) {
        commit("loaded");
        return;
      }

      commit("setClient", {
        firstName: response.data[0].firstName,
        lastName: response.data[0].lastName.toUpperCase(),
        email: response.data[0].email,
        phone: response.data[0].phone,
      });

      const reportList = [];
      response.data.forEach((element) => {
        reportList.push({
          id: element.id,
          dateTime: convertDbDateToJsDate(element.dateTime),
          type: element.type,
          typeOfReport:
            element.type === "PSYCHOLOGY"
              ? "Psychologique"
              : element.type === "PERMANENCY"
              ? "Permanence"
              : "-",
          socCollaboratorFirstName: element.socCollaboratorFirstName,
          socCollaboratorLastName: element.socCollaboratorLastName.toUpperCase(),
          socCollaboratorId: element.socCollaboratorId,
        });
      });

      commit("setReports", reportList);
      commit("loaded");
    },
  },

  getters: {
    reports(state) {
      return state.reports;
    },

    totalReports(state) {
      return state.reports.length;
    },

    client(state) {
      return state.client;
    },

    isLoading(state) {
      return state.isLoading;
    },
  },
};
