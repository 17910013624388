export default {
  namespaced: true,

  state: {
    typologiesList: [
      { value: "FIRST_MEETING", text: "1er rendez-vous" },
      { value: "FOLLOWING_SOC_PERMANENCY", text: "Suite à une permanence SOC" },
      {
        value: "FOLLOWING_ONE_TO_ONE_PAST_MEETING",
        text: "Suite à un rendez-vous pour une consultation individuelle dans le passé",
      },
      { value: "FOLLOWING_SOC_WORKSHOP", text: "Suite à un atelier SOC" },
      { value: "OTHER", text: "Autre" },
    ],

    toolsList: [
      { value: "INTERESTS_TEST", text: "Test intérêts" },
      { value: "VALUES_TEST", text: "Test valeurs" },
      { value: "PERSONALIZED_TEST", text: "Test personnalité" },
      { value: "OTHER", text: "Autre" },
    ],

    deliveredServicesList: [
      { value: "JOB_COACHING", text: "Coaching emploi" },
      { value: "ORIENTATION_ADVICE", text: "Conseil en orientation" },
      { value: "METHODOLOGY_ADVICE", text: "Conseil méthodologique" },
      { value: "PSYCHOLOGICAL_SUPPORT", text: "Soutien psychologique" },
      { value: "SKILLS_ASSESSMENT", text: "Bilan de compétences" },
      {
        value: "JOB_INTERVIEW_SIMULATION",
        text: "Simulation entretien embauche",
      },
    ],
  },

  mutations: {},

  actions: {},

  getters: {
    typologiesList(state) {
      return state.typologiesList;
    },

    toolsList(state) {
      return state.toolsList;
    },

    deliveredServicesList(state) {
      return state.deliveredServicesList;
    },
  },
};
