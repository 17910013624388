import Vue from "vue";

export default {
  namespaced: true,

  state: {
    token: null,
    user: {
      pernum: "",
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
    },
  },
  mutations: {
    storeToken(state, token) {
      state.token = token;
    },

    storeUser(state, user) {
      state.user = user;
    },

    updateUser(state, newData) {
      Object.keys(state.user).forEach((attr) => {
        if (Object.prototype.hasOwnProperty.call(newData, attr)) {
          state.user[attr] = newData[attr];
        }
      });
    },

    clearToken(state) {
      state.token = null;
    },

    clearUser(state) {
      state.user = {
        pernum: "",
        firstName: "",
        lastName: "",
        email: "",
        roles: [],
      };
    },
  },

  actions: {
    storeAuthenticationData({ commit }, authData) {
      commit("storeToken", authData.token);
      commit("storeUser", authData.user);
    },

    storeAuthenticationDataFromToken({ commit }, token) {
      let pernum = (token.employeeNumber == null) ? null : token.employeeNumber;
      let userRoles = [];
      if (process.env.VUE_APP_KEYCLOAK_CLIENT_ID in token.resource_access) {
        userRoles =
          token.resource_access[process.env.VUE_APP_KEYCLOAK_CLIENT_ID].roles;
      }

      commit("storeToken", token);

      commit("storeUser", {
        pernum: pernum,
        firstName: token.givenName,
        lastName: token.family_name.toUpperCase(),
        email: token.email,
        roles: userRoles,
      });
    },

    updateUser({ commit }, newData) {
      commit("updateUser", newData);
    },

    logout({ commit }) {
      localStorage.removeItem("vue-token");
      localStorage.removeItem("vue-refresh-token");
      commit("clearUser");
      Vue.$keycloak.logout({});
    },
  },

  getters: {
    token(state) {
      return state.token;
    },

    user(state) {
      return state.user;
    },

    isAuthenticated(state) {
      return state.token !== null;
    },

    hasRole: (state) => (roles) => {
      if (!roles) {
        return false;
      }

      let hasRole = false;
      roles.every((role) => {
        if (state.user.roles.includes(role)) {
          hasRole = true;
          return false;
        }

        return true;
      });

      return hasRole;
    },
  },
};
