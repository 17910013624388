import Vue from "vue";
import axios from "axios";

const instance = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
  headers: {'api-key': process.env.VUE_APP_SOC_WS_API_KEY},
});

// instance.defaults.headers.common["SOMETHING"] = "something";
instance.interceptors.request.use((config) => {
  config.headers["Authorization"] = "Bearer " + Vue.$keycloak.token;
  Vue.$log.info("Request interceptor", config);
  return config;
});

instance.interceptors.response.use((response) => {
  Vue.$log.info("Response interceptor", response);
  return response;
});

export default instance;
