import axios from "@/axios-auth";
import moment from "moment";
import { isObjectEmpty } from "@/utils/object";

export default {
  namespaced: true,

  state: {
    report: {
      id: null,
      phone: null,
      gender: null,
      lastName: null,
      firstName: null,
      userId: null,
      birthDate: null,
      nationality: null,
      email: null,
      situation: [],
      priorExperience: null,
      admission: null,
      clientCategory: null,
      levelOfStudy: null,
      principalFaculty: null,
      studies: null,
      degreeType: null,
      dateTime: null,
      typology: null,
      typologyOther: null,
      motivation: null,
      socCollaboratorId: null,
      socCollaboratorFirstName: null,
      socCollaboratorLastName: null,
      socCollaboratorComments: null,
      themes: [],
      socCollaboratorDecision: null,
      deliveredServices: [],
      tools: [],
      toolOther: null,
      duration: null,
    },

    reportExists: false,
  },

  mutations: {
    updateObjReport(state, newData) {
      Object.keys(state.report).forEach((attr) => {
        if (Object.prototype.hasOwnProperty.call(newData, attr)) {
          state.report[attr] = newData[attr];
        }
      });
    },

    reset(state) {
      state.report = {
        id: null,
        phone: null,
        gender: null,
        lastName: null,
        firstName: null,
        userId: null,
        birthDate: null,
        nationality: null,
        email: null,
        situation: [],
        priorExperience: null,
        admission: null,
        clientCategory: null,
        levelOfStudy: null,
        principalFaculty: null,
        studies: null,
        studyPlan: null,
        degreeType: null,
        dateTime: null,
        typology: null,
        typologyOther: null,
        motivation: null,
        socCollaboratorId: null,
        socCollaboratorFirstName: null,
        socCollaboratorLastName: null,
        socCollaboratorComments: null,
        themes: [],
        socCollaboratorDecision: null,
        deliveredServices: [],
        tools: [],
        toolOther: null,
        duration: null,
      };
    },

    reportExists(state, exists) {
      state.reportExists = exists;
    },
  },

  actions: {
    async loadById({ commit }, id) {
      commit("commonReport/loading", {}, { root: true });

      let response = await axios.get(`psychology/report/${id}`);

      commit("updateObjReport", response.data);
      commit("commonReport/loaded", {}, { root: true });
    },

    async loadPreFilledReport({ dispatch, commit }, defaultData) {
      //@todo check defaultData (clientEmail & appointmentDateTime)

      commit("commonReport/loading", {}, { root: true });

      commit("reset");

      try {
        let response = await axios.get(
          `psychology/report/user/${defaultData.clientEmail}/last`
        );

        const preFilledReport = { ...response.data };

        Object.assign(preFilledReport, {
          id: null,
          dateTime: defaultData.appointmentDateTime,
          socCollaboratorComments: null,
          socCollaboratorDecision: null,
          duration: null,
          socCollaboratorId: this.getters[`user/user`].pernum,
          socCollaboratorFirstName: this.getters[`user/user`].firstName,
          socCollaboratorLastName: this.getters[`user/user`].lastName,
        });

        commit("reportExists", true);
        commit("updateObjReport", preFilledReport);
        commit("commonReport/loaded", {}, { root: true });
      } catch (error) {
        if (error.response.status === 404) {
          commit("reportExists", false);
          let person = await dispatch(
            "person/searchByEmail",
            defaultData.clientEmail,
            {
              root: true,
            }
          );

          const preFilledReport = {
            email: defaultData.clientEmail,
            dateTime: defaultData.appointmentDateTime,
            socCollaboratorId: this.getters[`user/user`].pernum,
            socCollaboratorFirstName: this.getters[`user/user`].firstName,
            socCollaboratorLastName: this.getters[`user/user`].lastName,
          };

          if (!isObjectEmpty(person)) {
            const birthDate = moment(person.birthDate, "DD-MM-YYYY");

            let clientCategory = "N/A";
            person.categories.findIndex(function (item) {
              clientCategory = "PAT";
              if (item.category == "PAT") {
                return true;
              }
            });
            person.categories.findIndex(function (item) {
              if (item.category == "STUDENT") {
                clientCategory = "STUDENT";
                return true;
              }
            });

            Object.assign(preFilledReport, {
              gender: person.genderType,
              firstName: person.firstName,
              lastName: person.lastName,
              birthDate: birthDate.format("YYYY-MM-DD"),
              userId: person.personNumber,
              nationality: person.nativeCountry,
              clientCategory: clientCategory,
              levelOfStudy: person.levelofstudy,
              principalFaculty: person.principalFaculty,
              studies: person.studyProgram,
              studyPlan: person.studyPlan,
            });
          }
          commit("updateObjReport", preFilledReport);

          commit("commonReport/loaded", {}, { root: true });
        } else {
          throw error;
        }
      }
    },

    async save({ getters, commit }) {
      commit("commonReport/saving", {}, { root: true });

      const payload = Object.assign({}, getters.report);

      let response = await axios.post("psychology/report", payload);

      commit("commonReport/saved", {}, { root: true });

      return response;
    },

    updateObjReport({ commit }, newData) {
      commit("updateObjReport", newData);
    },

    reset({ commit }) {
      commit("reset");
    },
  },

  getters: {
    report(state) {
      return state.report;
    },

    reportExists(state) {
      return state.reportExists;
    },

    unilMember(state) {
      return state.report.userId !== null;
    },
  },
};
