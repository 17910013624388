<template>
  <v-card>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <p class="text-h5">Motif de la consultation</p>
        <form>
          <v-select
            v-model="motivationModel"
            class="mb-4"
            :items="
              isAuthenticated
                ? motivationsByAudience('permanency-unilMember')
                : motivationsByAudience('permanency-externalToUnil')
            "
            :error-messages="validationErrors('motivationModel')"
            label="Motif"
            required
            @change="$v.motivationModel.$touch()"
            @blur="$v.motivationModel.$touch()"
          >
          </v-select>

          <div class="d-flex justify-end">
            <v-btn class="button-shadow" color="primary" @click="next">
              Suivant
            </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      motivationModel: null,
    };
  },

  computed: {
    ...mapGetters("user", {
      isAuthenticated: "isAuthenticated",
    }),

    ...mapGetters("commonReportForm", ["motivationsByAudience"]),

    ...mapGetters("appointmentForm", {
      currentStep: "step",
    }),

    rules() {
      return {
        motivationModel: { required },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "motivationModel") {
          if (this.$v.motivationModel.$dirty) {
            !this.$v.motivationModel.required &&
              errors.push("Le motif de la consultation est requis !");
          }

          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("appointment", ["updateObjAppointment"]),

    ...mapActions("appointmentForm", ["incrementStep"]),

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjAppointment({
        motivation: this.motivationModel,
      });

      this.incrementStep();
    },
  },
};
</script>
