<template>
  <v-card>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <form>
          <p class="text-h5">Informations personnelles</p>
          <v-text-field
            v-model.lazy="lastName"
            :disabled="isAuthenticated ? true : false"
            class="mb-4"
            :error-messages="validationErrors('lastName')"
            label="Nom de famille"
            required
            @change="$v.lastName.$touch()"
            @blur="$v.lastName.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model.lazy="firstName"
            :disabled="isAuthenticated ? true : false"
            class="mb-4"
            :error-messages="validationErrors('firstName')"
            label="Prénom"
            required
            @change="$v.firstName.$touch()"
            @blur="$v.firstName.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model.lazy="email"
            :disabled="isAuthenticated ? true : false"
            class="mb-4"
            :error-messages="validationErrors('email')"
            label="Adresse e-mail"
            required
            @change="$v.email.$touch()"
            @blur="$v.email.$touch()"
          >
          </v-text-field>
          <div class="d-flex justify-center">
            <v-btn class="button-shadow" color="primary" @click="prev"> Précédent </v-btn>
            <v-spacer></v-spacer>
            <v-btn class="button-shadow" color="primary" @click="next">
              Suivant
            </v-btn>
          </div>
        </form>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],

  validations() {
    return this.rules;
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      pernum: null,
    };
  },

  computed: {
    ...mapGetters("user", {
      isAuthenticated: "isAuthenticated",
      connectedUser: "user",
    }),

    ...mapGetters("booking", {
      currentStep: "step",
    }),

    rules() {
      return {
        lastName: { required },
        firstName: { required },
        email: { required, email },
      };
    },

    validationErrors() {
      return (field) => {
        const errors = [];

        if (field === "lastName") {
          if (this.$v.lastName.$dirty) {
            !this.$v.lastName.required &&
              errors.push("Le nom de famille est requis !");
          }
          return errors;
        }

        if (field === "firstName") {
          if (this.$v.firstName.$dirty) {
            !this.$v.firstName.required &&
              errors.push("Le prénom est requis !");
          }

          return errors;
        }

        if (field === "email") {
          if (this.$v.email.$dirty) {
            !this.$v.email.email &&
              errors.push("L'adresse e-mail doit être valide !");
            !this.$v.email.required &&
              errors.push("L'adresse e-mail est requise !");
          }

          return errors;
        }

        return errors;
      };
    },
  },

  methods: {
    ...mapActions("appointmentForm", ["incrementStep", "decrementStep"]),
    ...mapActions("appointment", ["updateObjAppointment"]),
    ...mapActions("person", ["searchByPernum"]),
    
    prev() {
      this.decrementStep();
    },

    next() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.updateObjAppointment({
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        pernum: this.pernum,
      });

      this.incrementStep();
    },
  },

  async created() {
    if (this.isAuthenticated) {
      let person = await this.searchByPernum(this.connectedUser.pernum);

      this.firstName = this.connectedUser.firstName;
      this.lastName = this.connectedUser.lastName;
      this.email = this.connectedUser.email;
      // If the person is categorized as STUDENT or PAT, their pernum will be stored in the database.
      if (person?.categories?.[0]?.category) {
        this.pernum = this.connectedUser.pernum;
      }
    }
  },
};
</script>
