import Vue from "vue";
import axios from "axios";

// function sleep(ms) {
//   return new Promise((resolve) => setTimeout(resolve, ms));
// }

export default {
  namespaced: true,

  state: {},

  mutations: {},

  actions: {
    async searchByPernum(context, pernum) {
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_PERSON_BY_PERNUM_URL + "/" + pernum,
          {
            headers: {
              "Authorization": "Bearer " + Vue.$keycloak.token,
            },
          }
        );

        return response.data;
      } catch (error) {
        if (error.response.status === 404) {
          return {};
        }
      }
    },

    async searchByEmail(context, email) {
      //await sleep(1000);
      try {
        let response = await axios.get(
          process.env.VUE_APP_API_PERSON_BY_EMAIL_URL + email,
          {
            headers: {
              "Authorization": "Bearer " + Vue.$keycloak.token,
            },
          }
        );

        return response.data;
      } catch (error) {
        if (error.response.status === 404) {
          return {};
        }
      }
    },
  },

  getters: {},
};
